import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import authContext from "../../auth-context";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import AppLayout from "./AppLayout";
import "react-datepicker/dist/react-datepicker.css";
import "../../CustomCSS/custom.css";
import checked from "../../images/checked.svg";
import document from "../../images/document.svg";
import { useTranslation } from "react-i18next";
import { API } from "../../Constants/Global";
import Dashboardlogo from "../../images/Logo1.png";
const PaymentResponse = () => {
  const navigate = useNavigate();
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const [responseObj, setResponseObj] = useState(null);
  const { ReferenceId, CheckoutID } = useParams();
  const getRedirectUrl = localStorage.getItem("redirectUrl");

  const handleContinue = () => {
    let config = {
      headers: {
        APICODE: token,
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(`${API}/subscriber/profile`, config)
      .then((response) => {
        if (response.data.isSuccess) {
          localStorage.setItem("user", JSON.stringify(response.data.Data));
          let _redirectUrl = JSON.parse(getRedirectUrl);
          if (_redirectUrl?.page) {
            localStorage.removeItem("redirectUrl");
            navigate("/sports/booking")
          }
        } else {
        }
      })
      .catch((error) => console.log(error));
  }

  const handlePayment = (BillId) => {
    setIsLoading(true);
    let config = {
      headers: {
        APICODE: token,
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}bill/paymentinitiate?BillId=${BillId}`,
        config
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.isSuccess) {
          if (response.data.PaymentGatewayInfo != null) {
            if (response.data.PaymentGatewayInfo.result.code === "000.200.100") {
              window.location = response.data.PaymentGatewayUrl;
            }
          }
        } else {
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const BillPrint = (BillId) => {
    setIsLoading(true);
    let config = {
      headers: {
        APICODE: token,
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}bill/subscriber?BillId=${BillId}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          let PdfLink = response.data.Data;
          window.open(PdfLink, '_blank');
          setIsLoading(false);
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    const lng = localStorage.getItem("i18nextLng");
    let config = {
      headers: {
        APICODE: token,
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}bill/paymentstatus?ReferenceId=${ReferenceId}&CheckoutID=${CheckoutID}`,
        config
      )
      .then((response) => {
        setIsLoading(false);
        setResponseObj(response.data);
        if (response.data.isSuccess) {

        } else {
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);
  return isLoading ? (
    <div className="loader-body">
      <div className="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div>
      {token ? (
        <AppLayout>
          <div className="col-xl-12 order-xl-1">
            <div className="card shadow">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12" style={{ textAlign: "center", paddingTop: "50px" }}>
                    {!responseObj?.isSuccess && <img src={document} style={{ width: "100px" }} />}
                    {responseObj?.isSuccess && <img src={checked} style={{ width: "100px" }} />}
                  </div>
                  <div className="col-md-12" style={{ textAlign: "center", paddingTop: "50px" }}>
                    {responseObj?.isSuccess && <h2 style={{ color: "#253c78" }}>{t("Thankyou")} </h2>}
                    {!responseObj?.isSuccess && <h2 style={{ color: "#253c78" }}>{t("Sorry")} </h2>}
                    <p>{responseObj?.Message}</p>
                    {/* {
                            getRedirectUrl &&
                            <button
                              className="btn-gradient-primary button"
                              style={{ backgroundColor: "#e0be4f" }}
                              onClick={() => handleContinue()
                              }
                            >
                              <i className="mdi mdi-redo"></i>{" "}
                              {t("Continue")}
                            </button>
                          } */}
                  </div>

                  <div className="col-md-12">
                    <center>
                      {(!responseObj?.isSuccess && responseObj?.Data) &&
                        <button
                          className="btn-gradient-primary button"
                          style={{ backgroundColor: "#e0be4f" }}
                          onClick={() => handlePayment(responseObj?.Data)
                          }
                        >
                          <i className="mdi mdi-redo"></i>{" "}
                          {t("RetryPayment")}
                        </button>
                      }
                      {responseObj?.isSuccess &&
                        <>
                          {
                            getRedirectUrl &&
                            <button
                              className="btn-gradient-primary button"
                              style={{ backgroundColor: "#e0be4f" }}
                              onClick={() => handleContinue()
                              }
                            >
                              <i className="mdi mdi-redo"></i>{" "}
                              {t("Continue")}
                            </button>
                          }

                          <button
                            className="btn-gradient-primary button"
                            onClick={() => BillPrint(responseObj?.Data)
                            }
                          >
                            <i className="mdi mdi-printer"></i>{" "}
                            {t("PrintBill")}
                          </button>
                        </>
                      }
                    </center>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </AppLayout>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};

export default PaymentResponse;
