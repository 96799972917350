import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import authContext from "../../auth-context";
import { Link, useParams, useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import axios from "axios";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import toast, { Toaster } from "react-hot-toast";
import { API } from "../../Constants/Global";

const Verification = (props) => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [OTP, setOTP] = useState("");
  const { token, userLogin, isLoggedIn } = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);
  function handleChange(event, data) {
    setOTP(event);
    setError(false);
  }



  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  const resend = () => {
    setIsLoading(true);
    const loginId = localStorage.getItem("loginId");
    let config = {
      headers: {
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}public/resendloginotp?Id=${loginId}`,
        config
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.isSuccess) {
          toast.success(response.data.Message);
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) => setIsLoading(false));
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const regData = {
      Mobile: mobNum,
      OTP: OTP,
      Id: localStorage.getItem("loginId"),
    };
    let config = {
      headers: {
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .post(
        `${API}public/subscriberlogin`,
        regData,
        config
      )
      .then((response) => {
        setIsLoading(false)
        if (response.data.isSuccess) {
          debugger;
          localStorage.setItem("token", response.data.Data);
          userLogin(response.data.Data);
          let config = {
            headers: {
              APICODE: response.data.Data,
              "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
            },
          };
          axios
            .get(`${API}/subscriber/profile`, config)
            .then((response1) => {
              if (response1.data.isSuccess) {
                localStorage.setItem("user", JSON.stringify(response1.data.Data));
                navigate("/main");
              } else {
                toast.error(response1.data.Message);
              }
            })
            .catch((error) => console.log(error));
          
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) => setIsLoading(false));
  };

  function SetMobile() {
    let mobile = localStorage.getItem("mobNum");
    let res = "";
    switch (mobile.length) {
      case 13:
        var get5 = mobile.substring(0, 5);
        if (get5 == "+9665") {
          res = mobile;
        }
        break;
      case 12:
        var get4 = mobile.substring(0, 4);
        if (get4 == "9665") {
          res = "+" +mobile;
        }
        break;
      case 10:
        var get2 = mobile.substring(0, 2);
        if (get2 == "05") {
          res = "+966" + mobile.substring(1, mobile.length);
        }
        break;
      case 9:
        var get1 = mobile.substring(0, 1);
        if (get1 == "5") {
          res = "+966" + mobile
        }
        break;
      default:
        res = mobile;
        break;
    }
    return res;
  }


  const mobNum = SetMobile();

  useEffect(() => {
    if (!localStorage.getItem("loginId")) {
      navigate("/login");
    }
  }, []);


  return (
    <div>
      {!token ? (
        isLoading ? (
          <div className="loader-body">
            <div className="circle-loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
        <AuthLayout>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
                padding: "25px",
              },
              success: {
                duration: 5000,
                theme: {
                  primary: "green",
                  secondary: "white",
                },
              },
              error: {
                duration: 5000,
                theme: {
                  primary: "red",
                  secondary: "black",
                },
              },
            }}
          />
          <div className="limiter">
            <div className="container-login100">
              <div className="wrap-login100 p-l-30 p-r-30 p-t-30 p-b-30">
                <form
                  className="login100-form validate-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <span
                    className="text-center p-b-20"
                    style={{ width: "100%" }}
                  >
                    <img
                      src="http://kssc.org.sa/assets/images/LogoEn.png"
                      alt=""
                      style={{ width: "70%" }}
                    />
                  </span>

                  <span className="login100-form-title p-b-20">
                    {t("verification ")}
                  </span>
                  <span
                    className="p-b-42"
                    style={{
                      color: "#ccc",
                      lineHeight: "1.2",
                      textTransform: "none",
                      width: "100%",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    {t("code")} <span>{mobNum}</span>
                  </span>

                  <div className="mb-6 text-center">
                    <div
                      id="otp"
                      className="flex justify-center wrap-input100 validate-input"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <OtpInput
                        value={OTP}
                        onChange={handleChange}
                        numInputs={4}
                        shouldAutoFocus="false"
                        isInputSecure="false"
                        containerStyle={"otp-div"}
                        inputStyle={"otp-input"}
                      />
                      {error && (
                        <p style={{ color: "red" }}>{t("OTPRequired")}</p>
                      )}
                    </div>
                  </div>
                  <div className="container-login100-form-btn p-t-25">
                    <button
                      type="submit"
                      disabled={OTP.length !== 4}
                      className="login100-form-btn"
                    >
                      {t("verify")}
                    </button>
                  </div>

                  <div className="text-center w-full p-t-40">
                    <span className="txt1">{t("Receive")}</span>
                    <Link
                      className="txt1 bo1 hov1"
                      onClick={resend}
                      to="#"
                      style={{ textDecoration: "none", margin: "0 2% 0 1%" }}
                    >
                      {t("Resend")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </AuthLayout>
      )

      ) : (
        navigate("/main")
      )}
    </div>
  );
};

export default Verification;
