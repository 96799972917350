import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import authContext from "../../auth-context";
import AppLayout from "./AppLayout";
import { useTranslation } from "react-i18next";

const Empowerment = () => {
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  let fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 500));
  };

  useEffect(() => {
    fakeRequest().then(() => {
      setIsLoading(false);
    });
  }, []);

  const navigate = useNavigate();
  return isLoading ? (
    // <div className="loader-body ng-hide" data-loading="">
    //   <div className="loader"></div>
    // </div>
    <div className="loader-body">
      <div className="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div>
      {token ? (
        <AppLayout>
          <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-account-multiple-outline"></i>
              </span>{" "}
              {t("Empowerment")}{" "}
            </h3>
          </div>
        </AppLayout>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};

export default Empowerment;
