import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      Mon: "Mon",
      Tue: "Tue",
      Wed: "Wed",
      Thu: "Thu",
      Fri: "Fri",
      Sat: "Sat",
      Sun: "Sun",
      login: "Login",
      MobileRequired: "Mobile No is required",
      MobileInvalid: "Mobile No is invalid",
      new: "New at KSSC?",
      create: "Create Account",
      member: "Subscriber Registrations",
      SubscriberTypes: "Subscriber Types",
      Subscribe: "Select",
      NotSubscriber :"You need to select Subscriber Type",
      ID: "National ID",
      ID_Required: "National ID*",
      NationalID: "National ID",
      DOB_Required: "Date of Birth*",
      DOB: "Date of Birth",
      DateOfBirth: "Date of Birth",
      HijriDate: "Hijri Date",
      MaritalStatus_Required: "Marital Status*",
      MaritalStatus: "Marital Status",
      Married: "Married",
      Widowed: "Widowed",
      Separated: "Separated",
      Divorced: "Divorced",
      Single: "Single",
      Name_Required: "Name*",
      Name: "Name",
      Email_Required: "Email*",
      Email: "Email",
      Mobile_Required: "Mobile*",
      Mobile: "Mobile",
      Male: "Male",
      Female: "Female",
      StartDate: "Start Date",
      StartDate_Required: "Start Date*",
      EndDate_Required: "End Date*",
      EndDate: "End Date",
      EndDateRequired: "End date is required",
      Gender: "Gender is required",
      IDRequired: "National ID is required",
      NameRequired: "Name is required",
      DOBRequired: "Date of Birth is required",
      StartDateRequired: "Start Date is required",
      MaritalRequired: "Marital Status is required",
      EmailRequired: "Email is required",
      IDInvalid: "National ID is invalid",
      EmailInvalid: "Email is invalid",
      Register: "REGISTER",
      Account: "Already have an account?",
      verification: "VERIFICATION",
      code: "Enter the code we just sent to your mobile phone",
      verifyOTP: "Verify OTP",
      verify: "VERIFY",
      Membershiptype: "Subscriber type is required",
      Receive: "Didn't receive the code?",
      Resend: "Resend",
      Next: "NEXT",
      GetOTP: "GET OTP",
      OTPRequired: "OTP is required",
      Previous: "PREVIOUS",
      Skip: "Skip",
      ReqGatepass: "Request Gate pass",
      Dashboard: "Dashboard",
      Sports: "Sports",
      Clinic: "Clinic",
      Events: "Events",
      Empowerment: "Empowerment",
      Logout: "Logout",
      Notifications: "Notifications",
      SeeAllNotifications: "See all notifications",
      Schedule: "Schedule",
      BookASchedule: "Book a Sport",
      MembershipId: "Membership Id",
      ServiceName: "Service",
      Sessions: "Sessions",
      AttendedSessions: "Attended Sessions",
      GrandTotal: "Grand Total",
      IsPaid: "Is Paid",
      Status: "Status",
      Pending:"Pending",
      Approved:"Approved",
      Rejected:"Rejected",
      Payment:"Payment",
      Appointments: "Appointments",
      BookAppointment: "Book an Appointment",
      AppointmentNo: "Appointment #",
      AppointmentDate: "Appointment Date",
      TherapistName: "Therapist Name",
      Events: "Events",
      selectService: "Please select a Service",
      select: "select",
      developedByIBS: "Designed and Developed by IBS",
      selectTherapist: "Select a Therapist",
      selectDate: "Select a Date",
      slots: "Available Slots",
      notAvailable: "Not Available",
      Reserved: "Reserved with other",
      Available: "Available",
      Summary: "Booking Summary",
      Duration: "Duration",
      SubscriptionSummary: "Subscription Summary",
      Therapist: "Therapist",
      Date: "Date",
      slotSelected: "Slot Selected",
      grandTotal: "Grand Total",
      therapistNotAvailable: "Therapist not available",
      slotsNotAvailable: "Slots not available",
      sessionsNotAvailable: "Sessions not available",
      GatePass: "Gate Pass",
      Renewal: "Renewal",
      SubscriptionHistory: "Subscription History",
      Trainers: "Trainers",
      Book: "BOOK",
      clinicalServices: "Clinical Services",
      sportServices: "Sport Services",
      clinicalBookings: "My Clinical Bookings",
      sportsBookings: "My Sports Bookings",
      YourGatePasses: "Your Gate Passes",
      NoGatePasses: "No gate passes found",
      selectDate: "Select Date",
      passSlot: "Select a Pass Slot",
      submit: "SUBMIT",
      Sessions: "Sessions",
      Sort: "Sort",
      PriceLowHigh: "Price (Low to High)",
      PriceHighLow: "Price (High to Low)",
      Profile: "Profile",
      PersonalDetails: "PERSONAL DETAILS",
      NoRecordS: "No Records found",
      Nationality: "Nationality",
      Indian: "Indian",
      Pakistani: "Pakistani",
      Emirati: "Emirati",
      Saudi: "Saudi",
      Disability: "Disability",
      VisionImpairment: "Vision Impairment",
      Hardofhearing: "Hard of hearing",
      Mentalhealthconditions: "Mental health conditions",
      None: "None",
      ProfessionalDetails: "PROFESSIONAL DETAILS",
      Education: "Education",
      Major: "Major",
      Occupation: "Occupation",
      AreasofInterest: "Areas of Interest",
      Cultural: "Cultural",
      Technical: "Technical",
      ContactDetails: "CONTACT DETAILS",
      EmergencyContacts: "Emergency Contacts",
      AtleastOneEmergency: "Please enter at least one emergency contact",
      Update: "Update",
      Subscription: "Subscription",
      SubscriptionType: "Subscription Type",
      Type: "Type",
      ValidFrom: "Valid From",
      ValidTo: "Valid To",
      ChangeMobNum: "Change Mobile Number",
      ClickToChange: "Please click below to change this mobile number",
      EnternewNo: " Enter the new mobile number",
      MyEvents: "My Events",
      Attending: "Attending",
      NotAttending: "Not Attending",
      Yes: "Yes, I'm interested",
      No: "No, I can't attend",
      Freeze: "Freeze",
      FreezeMembership: "Freeze Membership",
      PaymentStatus: "Payment Status",
      Thankyou: "Thank you",
      Sorry:"Sorry",
      RetryPayment: "Retry Payment",
      PrintBill: "Print Bill",
      Time:"Time",
      UpdateAppointment: "Update Appointment",
      BookedSessions: "Booked Sessions",
      AttendedSessions: "Attended Sessions",
      UpdateBooking: "Update Booking",
      Continue: "Continue",
      FreezeStartDate: "Start Date",
      FreezeEndDate: "End Date",
      ConditionsForEnteringthePool : "Conditions for entering the pool",
      swimmingPoolTests: "To be healthy according to (Swimming Pool Tests)",
      TestsAreFollows : "Tests are as follows",
      HIVTest : "HIV test",
      Syphilis : "Syphilis examination",
      LiverScreening : "Liver screening",
      Urinalysis : "Urinalysis",
      BloodImage : "Blood image",
      Today: "Today",
      Tomorrow: "Tomorrow"
    },
  },

  ar: {
    translation: {
      Mon: "الأثنين",
      Tue: "الثلاثاء",
      Wed: "الأربعاء",
      Thu: "الخميس",
      Fri: "الجمعه",
      Sat: "السبت",
      Sun: "الأحد",
      login: "تسجيل الدخول",
      Mobile: "التليفون المحمول*",
      MobileRequired: "رقم الجوال مطلوب",
      MobileInvalid: "رقم الجوال غير صالح",
      new: "جديد في KSSC?",
      create: "إنشاء حساب",
      member: "تسجيل المشترك",
      SubscriberTypes: "أنواع المشتركين",
      Subscribe: "تحديد",
      NotSubscriber :"تحتاج إلى تحديد نوع المشترك",
      ID_Required: "الهوية الوطنية*",
      ID: "الهوية الوطنية",
      DOB_Required: "تاريخ الولادة*",
      DOB: "تاريخ الولادة",
      DateOfBirth: "تاريخ الولادة",
      HijriDate: "التاريخ الهجري",
      MaritalStatus: "الحالة الحالة الإجتماعية*",
      Married: "زوجي",
      Widowed: "ترمل",
      Separated: "منفصل",
      Divorced: "مطلق",
      Single: "غير مرتبطة",
      Name_Required: "اسم*",
      Name: "اسم",
      Male: "ذكر",
      Female: "أنثى",
      StartDate_Required: "تاريخ البدء*",
      EndDate_Required: "تاريخ الانتهاء*",
      StartDate: "تاريخ البدء",
      EndDate: "تاريخ الانتهاء",
      EndDateRequired: "تاريخ الانتهاء مطلوب",
      Gender: "الجنس مطلوب",
      Email: "بريد الالكتروني*",
      IDRequired: "الرقم القومي مطلوب",
      NameRequired: "مطلوب اسم",
      DOBRequired: "تاريخ الميلاد مطلوب",
      StartDateRequired: "تاريخ البدء مطلوب",
      EmailRequired: "البريد الالكتروني مطلوب",
      MaritalRequired: "الحالة الاجتماعية مطلوبة",
      IDInvalid: "الرقم القومي غير صالح",
      EmailInvalid: "البريد الإلكتروني غير صالح",
      Register: "تسجيل",
      Account: "هل لديك حساب?",
      verification: "تحقق",
      code: "أدخل الرمز الذي أرسلناه للتو إلى هاتفك المحمول",
      verifyOTP: "تحقق من OTP",
      verify: "تحقق",
      Membershiptype: "نوع المشترك مطلوب",
      Receive: "لم يصلك الرمز?",
      Resend: "إعادة إرسال",
      Next: "التالي",
      GetOTP: "احصل على OTP",
      OTPRequired: "مطلوب OTP",
      Previous: "السابق",
      Skip: "يتخطى",
      ReqGatepass: "طلب تصريح دخول",
      Dashboard: "لوحة التحكم",
      Sports: "الخدمات الرياضية",
      Clinic: "العيادات الصحية",
      Events: "الفعاليات والبرامج",
      Empowerment: "التمكين المجتمعي",
      Logout: "تسجيل خروج",
      Notifications: "إشعارات",
      SeeAllNotifications: "اطلع على جميع الإشعارات",
      Schedule: "برنامج",
      BookASchedule: "احجز رياضة",
      MembershipId: "معرف العضوية",
      ServiceName: "خدمة",
      Sessions: "الجلسات",
      AttendedSessions: "الجلسات التي حضرها",
      GrandTotal: "المجموع الإجمالي",
      IsPaid: "مدفوع",
      Status: "حالة",
      Pending:"قيد الانتظار",
      Approved:"موافق عليه",
      Rejected:"مرفوض",
      Payment:"قسط",
      Appointments: "تعيينات",
      BookAppointment: "احجز موعدًا",
      AppointmentNo: "رقم الموعد",
      AppointmentDate: "تاريخ الموعد",
      TherapistName: "اسم المعالج",
      selectService: "الرجاء تحديد خدمة",
      select: "تحديد",
      developedByIBS: "تصميم وتطوير IBS",
      selectTherapist: "اختر معالجًا",
      selectDate: "حدد التاريخ",
      slots: "فتحات متاحة",
      notAvailable: "غير متوفر",
      Reserved: "محجوزة مع الآخرين",
      Available: "متاح",
      Summary: "ملخص الكتاب",
      Duration: "مدة",
      SubscriptionSummary: "ملخص الاشتراك",
      Therapist: "المعالج",
      Date: "تاريخ",
      slotSelected: "الفتحة المُحددة",
      grandTotal: "المبلغ الإجمالي",
      therapistNotAvailable: "المعالج غير متوفر",
      slotsNotAvailable: "الفتحات غير متوفرة",
      sessionsNotAvailable: "الجلسات غير متوفرة",
      GatePass: "عبور البوابة",
      Renewal: "التجديد",
      SubscriptionHistory: "سجل الاشتراك",
      Trainers: "المدربون",
      Book: "الحجز",
      clinicalServices: "الخدمات السريرية",
      sportServices: "الخدمات الرياضية",
      clinicalBookings: "حجوزاتي السريرية",
      sportsBookings: "حجوزاتي الرياضية",
      YourGatePasses: "يمر بوابتك",
      NoGatePasses: "لم يتم العثور على ممرات بوابة",
      selectDate: "حدد تاريخ",
      passSlot: "حدد فتحة مرور",
      submit: "إرسال",
      Sessions: "الجلسات",
      Sort: "نوع",
      PriceLowHigh: "السعر من الارخص للاعلى",
      PriceHighLow: "السعر الاعلى الى الادنى",
      Profile: "حساب تعريفي",
      PersonalDetails: "تفاصيل شخصية",
      NoRecordS: "لا توجد سجلات",
      Nationality: "جنسية",
      Indian: "هندي",
      Pakistani: "باكستاني",
      Emirati: "إماراتي",
      Saudi: "سعودي",
      maritalStatus: "الحالة الحالة الإجتماعية",
      Disability: "عجز",
      VisionImpairment: "ضعف الرؤية",
      Hardofhearing: "صعوبة السمع",
      Mentalhealthconditions: "حالات الصحة العقلية",
      None: "لا أحد",
      ProfessionalDetails: "تفاصيل احترافية",
      Education: "تعليم",
      Major: "رائد",
      Occupation: "احتلال",
      AreasofInterest: "مجالات الاهتمام",
      Cultural: "ثقافي",
      Technical: "اصطلاحي",
      ContactDetails: "بيانات المتصل",
      email: "بريد الالكتروني",
      EmergencyContacts: "جهة اتصال للطوارئ",
      AtleastOneEmergency: "الرجاء إدخال جهة اتصال طوارئ واحدة على الأقل",
      mobile: "التليفون المحمول",
      Update: "تحديث",
      Subscription: "الاشتراك",
      SubscriptionType: "نوع الاشتراك",
      Type: "نوع",
      ValidFrom: "صالح من تاريخ",
      ValidTo: "صالحة ل",
      ChangeMobNum: "تغيير رقم الهاتف المحمول",
      ClickToChange: "الرجاء النقر أدناه لتغيير رقم الهاتف المحمول هذا",
      EnternewNo: "يرجى إدخال رقم الهاتف المحمول الجديد",
      MyEvents: "أحداثي",
      Attending: "حضور",
      NotAttending: "عدم حضوره",
      Yes: "نعم انا مهتم",
      No: "لا ، لا يمكنني الحضور",
      Freeze: "Freeze",
      FreezeMembership: "Freeze Membership",
      PaymentStatus: "Payment Status",
      Thankyou: "Thank you",
      Sorry:"Sorry",
      RetryPayment: "Retry Payment",
      PrintBill: "Print Bill",
      Time:"Time",
      UpdateAppointment: "Update Appointment",
      BookedSessions: "Booked Sessions",
      AttendedSessions: "Attended Sessions",
      UpdateBooking: "Update Booking",
      Continue: "Continue",
      FreezeStartDate: "Start Date",
      FreezeEndDate: "End Date",
      ConditionsForEnteringthePool : "شروط دخول المسبح",
      swimmingPoolTests: "أن يكون سليم حسب ( فحوصات المسبح )",
      TestsAreFollows : "وهي",
      HIVTest : "فحص نقص المناعة ( الإيدز )",
      Syphilis : "فحص الزهري",
      LiverScreening : "فحص الكبد الوبائي",
      Urinalysis : "تحليل البول",
      BloodImage : "صورة الدم",
      Today: "اليوم",
      Tomorrow: "غداً"
    },
  },
};

i18n

  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng:
      localStorage.getItem("i18nextLng") == undefined
        ? "en"
        : localStorage.getItem("i18nextLng"),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
