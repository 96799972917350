import axios from "axios";

import React, { useEffect, useState, useReducer, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import CloseIcon from "@mui/icons-material/Close";
import "./custom.scss";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import maleImg from "../../images/male.jpg";
import femaleImg from "../../images/female.jpg";
import { makeStyles } from "@mui/styles";
import authContext from "../../auth-context";
import sportsIcon from "../../images/sports.jpg";
import noDataImg from "../../images/no-data.png";
import { API } from "../../Constants/Global";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "25px",
  },
  "& .MuiDialogActions-root": {
    padding: "10px",
  },
  "& .MuiInputLabel-root": {
    color: "#43425d",
    fontWeight: "bold",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    width: "360px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const { t, i18n } = useTranslation();
  return (
    <DialogTitle
      className={i18n.language === "en" ? "ltr" : "rtl"}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: i18n.language === "en" ? 8 : "auto",
            left: i18n.language === "en" ? "auto" : 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
          }}
        >
        </CloseIcon>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const defaultValues = {
  account_name: "",
};
const HistoryPopup = (props) => {
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);

  const [subscriberTypes, setSubscriberTypes] = useState(null);
  const [selectedSubscriberType, setSelectedSubscriberType] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [HistoryPopup, setHistory] = useState();

  const [id, setId] = useState(null);
  const [error, setError] = useState(false);
  const { t, i18n } = useTranslation();
  const lng = localStorage.getItem("i18nextLng");
  const tkn = localStorage.getItem("token");
  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  const [openAlert, setOpenAlert] = useState(false);
  const [icon, setIcon] = useState("mail_outline");
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState(null);

  const ACTIONS = {
    next: "next",
    previous: "previous",
  };
  const formReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.next:
        return { step: state.step + 1 };
      case ACTIONS.previous:
        return { step: state.step - 1 };
      default:
        return state;
    }
  };
  function convert(str) {
    const date = new Date(str);
    const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const [renewalMinDate, setRenewalMinDate] = useState(null);
  const useStyles = makeStyles({
    root: {
      direction: i18n.language == "en" ? "ltr" : "rtl",
      backgroundColor: "red",
    },
  });
  const classes = useStyles();
  const [state, dispatch] = useReducer(formReducer, { step: 0 });
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  const subscriberTypeID = watch("MembershiptypeId", "");
  const Start_Date = watch("StartDate", null);
  const StartDate = convert(Start_Date);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => {
    props.onCloseModal(false);
  };
  function handleCloseModal(event, data) {
    setOpenAlert(false);
  }
  const navigate = useNavigate();

  useEffect(() => {
    const lng = localStorage.getItem("i18nextLng");
    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": lng === "ar" ? "Ar" : "En",
      },
    };
    const subscriberId = JSON.parse(localStorage.getItem("user"))?.SubscriberId;
    axios
      .get(
        `${API}subscriber/subscriberhistory?SubscriberId=${subscriberId}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setHistory(response.data.Data);
          setIsLoading(false);
        } else {
          toast.error(response.data.Message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);

  }, []);

  function BillPrint(BillId) {
    setIsLoading(true);
    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": lng === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}bill/subscriber?BillId=${BillId}`,
        config
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.isSuccess) {
          let PdfLink = response.data.Data;
          window.open(PdfLink, '_blank');
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }


  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
        Width: "800px",
      }}
    >
      <BootstrapDialog
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        style={{
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
        fullWidth={true}
        maxWidth={"md"}
      >
        {isLoading && (
          <div className="loader-popup">
            <div className="circle-loader" style={{ top: "50%" }}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerclassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
              padding: "25px",
            },
            // Default options for specific types
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "white",
              },
            },
            error: {
              duration: 5000,
              theme: {
                primary: "red",
                secondary: "black",
              },
            },
          }}
        />
        <BootstrapDialogTitle
          style={{ borderBottom: "1px solid #ccc" }}
          id="history-popup"
          onClose={handleClose}
        >
          <div>
            <h3>{t("SubscriptionHistory")}</h3>
          </div>
        </BootstrapDialogTitle>
        <DialogContent className={i18n.language === "en" ? "ltr" : "rtl"} style={{
          backgroundColor: "#eeedf3", paddingTop: "30px", maxHeight: "60vh",
          overflowY: "overlay"
        }}>
          {HistoryPopup && HistoryPopup.length != 0 ? (
            HistoryPopup?.map((obj, index) => {
              return (
                <div className="row" style={{}}>
                  <div className="col-lg-12">
                    <div className="job-card">
                      <div className={`${lngClass} job-card__content`}>

                        <div className="job-card_info" style={{ width: "33%" }}>
                          <p className="labelTitle"> {t("SubscriptionType")}</p>
                          <p
                            className="font-weight-bold"
                            style={{ marginBottom: "0px" }}
                          >
                            {i18n.language === "en"
                              ? obj.SubscriberTypeNameEng
                              : obj.SubscriberTypeNameAra}
                          </p>
                        </div>

                        <div className="job-card_info">
                          <p className="labelTitle">{t("StartDate")} </p>
                          <p className="font-weight-bold">{obj.StartDate}</p>
                        </div>
                        <div className="job-card_info">
                          <p className="labelTitle">
                            {" "}
                            {t("EndDate")} {" "}
                          </p>
                          <p className="font-weight-bold">
                            {obj.EndDate}
                          </p>
                        </div>
                        <div className="job-card_info">
                          <p className="labelTitle"> {t("GrandTotal")} </p>
                          <p className="font-weight-bold">{obj.GrandTotal}</p>
                        </div>

                        <div className="job-card_info">
                          <p className="labelTitle">{t("Status")}</p>
                          {(() => {
                            switch (obj.StatusId) {
                              case 1:
                                return (
                                  <span className="badge badge-warning">
                                    {t("Pending")}
                                  </span>
                                );
                                case 5:
                                  return (
                                    <span className="badge badge-warning">
                                      Payment Failed
                                    </span>
                                  );
                                case 2:
                                  return (
                                    <span className="badge badge-success">
                                      Paid
                                    </span>
                                  );
                                case 3:
                                  return (
                                    <span className="badge badge-danger">
                                      Cancelled
                                    </span>
                                  );
                              default:
                                return "---";
                            }
                          })()}
                        </div>


                        <div className="job-card_info" >
                          {obj?.BillId > 0 && <div>
                            <p className="labelTitle">{t("Payment")}</p>
                            <Link to="#" key={new Date().getTime()}
                              onClick={() => {
                                BillPrint(obj.BillId);
                              }} title="Click here to print bill"
                            >
                              <i
                                className="mdi mdi-receipt"
                                style={{ fontSize: "30px", lineHeight: "1", color: "#012690" }}
                              ></i>
                            </Link>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="empty-state"
              style={{ backgroundColor: "transparent", paddingTop: "6%" }}
            >
              <div className="empty-state__content">
                <div className="empty-state__icon">
                  <img src={noDataImg} alt="" />
                </div>
                <div className="empty-state__message">{t("NoRecordS")}</div>
              </div>
            </div>
          )}

        </DialogContent>
        <DialogActions>

        </DialogActions>
      </BootstrapDialog>
      )
    </div>
  );
};

export default HistoryPopup;
