import React, { useReducer, useEffect, useState, useContext } from "react";
import authContext from "../../auth-context";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AuthLayout from "./AuthLayout";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import OtpInput from "react-otp-input";
import toast, { Toaster } from "react-hot-toast";
import { makeStyles } from "@mui/styles";
import { API } from "../../Constants/Global";

const Registration = () => {
  const [subscriberTypes, setSubscriberTypes] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [id, setId] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const { token, userLogin, isLoggedIn } = useContext(authContext);
  const [value, setValue] = useState(new Date());
  const { t, i18n } = useTranslation();
  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  const useStyles = makeStyles({
    root: {
      direction: i18n.language == "en" ? "ltr" : "rtl",
      backgroundColor: "red",
    },
  });
  const [openDOB, setOpenDOB] = useState(false);
  const classes = useStyles();

  const handleChang = (newValue) => {
    setValue(newValue);
  };

  function convert(str) {
    const date = new Date(str);
    const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  const gendrID = watch("GenderId");
  const dob = watch("DOB");

  function handleChange(event, data) {
    setOTP(event);
    setError(false);
  }

  const ACTIONS = {
    next: "next",
    previous: "previous",
  };

  const formReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.next:
        return { step: state.step + 1 };
      case ACTIONS.previous:
        return { step: state.step - 1 };
      default:
        return state;
    }
  };

  const progress = "active";
  const [state, dispatch] = useReducer(formReducer, { step: 0, progress: "" });

  function SetMobile(mobile) {
    //let mobile = localStorage.getItem("mobNum");
    let res = "";
    switch (mobile.length) {
      case 13:
        var get5 = mobile.substring(0, 5);
        if (get5 == "+9665") {
          res = mobile.slice(1);
        }
        break;
      case 12:
        var get4 = mobile.substring(0, 4);
        if (get4 == "9665") {
          res = "+" +mobile;
        }
        break;
      case 10:
        var get2 = mobile.substring(0, 2);
        if (get2 == "05") {
          res = "+966" + mobile.slice(1);
        }
        break;
      case 9:
        var get1 = mobile.substring(0, 1);
        if (get1 == "5") {
          res = "+966" + mobile;
        }
        break;
      default:
        res = mobile;
        break;
    }
    return res;
  }


  //const mobNum = SetMobile();
  //localStorage.setItem("mobNum", mobNum);

  const onSubmit = (data) => {
    setIsLoading(true);
    data.WithSubscriberType = true;
    data.EndDate = endDate;
    data.DOB = convert(data.DOB);
    data.StartDate = convert(data.StartDate);
    let config = {
      headers: {
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .post(
        `${API}public/getregistrationotp`,
        data,
        config
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.isSuccess) {
          setId(response.data.Data);
          dispatch({ type: ACTIONS.next });
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) =>  setIsLoading(false));
  };



  const onSkip = () => {
    setIsLoading(true);
    const mobNum = SetMobile(watch("Mobile"));
   let data = {
     "Name": watch("Name"),
     "NationalID": watch("NationalID"),
     "DOB":convert(dob),
     "MaritalStatus": watch("MaritalStatus"),
     "Mobile": mobNum,
     "Email": watch("Email"),
     "GenderId": watch("GenderId"),
     "MembershiptypeId": 0,
     "StartDate": null,
     "EndDate": null,
     "WithSubscriberType": false,
   }
    let config = {
      headers: {
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .post(
        `${API}public/getregistrationotp`,
        data,
        config
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.isSuccess) {
          localStorage.setItem("mobNum", mobNum);
          setId(response.data.Data);
          dispatch({ type: ACTIONS.next });
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) =>  setIsLoading(false));
  };
  
  const submitRegistration = (data) => {
    setIsLoading(true);
    let _mobnum = localStorage.getItem("mobNum");
    const regData = {
      Mobile: _mobnum,
      OTP: OTP,
      Id: id,
    };
    let config = {
      headers: {
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .post(
        `${API}public/subscriberregistration`,
        regData,
        config
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.isSuccess) {
          localStorage.setItem("token", response.data.Data);
          userLogin(response.data.Data);
          navigate("/main", { replace: true });
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) =>  setIsLoading(false));
  };

  const resend = () => {
    setIsLoading(true);
    let config = {
      headers: {
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}public/ResendRegistrationOTP?Id=${id}`,
        config
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.isSuccess) {
          toast.success(response.data.Message);
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) =>  setIsLoading(false));
  };

  const submit1 = (data) => {
    setIsLoading(true);
    if (gendrID && dob) {
      let _dob = convert(dob);
      axios
        .get(
          `${API}public/getsubscribertypes?GenderId=${gendrID}&Dob=${_dob}`
        )
        .then((response) => {
          setIsLoading(false);
          //setSubscriberTypes(response.data.Data);
          dispatch({ type: ACTIONS.next });
        })
        .catch((error) =>  setIsLoading(false));
    }
  };

  const subscriberTypeID = watch("MembershiptypeId", "");
  const Start_Date = watch("StartDate", null);
  const StartDate = convert(Start_Date);

  useEffect(() => {
    if (
      subscriberTypeID != "" &&
      subscriberTypeID &&
      StartDate != "aN/aN/NaN" &&
      StartDate != "01/01/1970"
    ) {
      setIsLoading(true);
      let config = {
        headers: {
          "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
        },
      };
        axios
        .get(
          `${API}public/setenddateforselectedsubscribertype?SubscriberTypeId=${subscriberTypeID}&StartDate=${StartDate}`,
          config
        )
        .then((response) => {
          setIsLoading(false);
          if (response.data.isSuccess) {
            setEndDate(response.data.Data);
          } else {
            toast.error(response.data.Message);
          }
        })
        .catch((error) =>  setIsLoading(false));
    }
  }, [subscriberTypeID, StartDate]);

  //const mobNum = watch("Mobile", "");


  return (
    <div>
      {!token ? (
        isLoading ? (
          <div className="loader-body">
            <div className="circle-loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
        <AuthLayout>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
                padding: "25px",
              },
              success: {
                duration: 5000,
                theme: {
                  primary: "green",
                  secondary: "white",
                },
              },
              error: {
                duration: 5000,
                theme: {
                  primary: "red",
                  secondary: "black",
                },
              },
            }}
          />
          <div className="limiter">
            <div className="container-login100">
              <main className="container">
                <div className="progress__container">
                  <div className="progress__bar js-bar"></div>
                  <div className="progress__circle js-circle active">1</div>
                  <div
                    className={`progress__circle js-circle ${
                      (state.step === 1) | (state.step === 2) ? progress : ""
                    }`}
                  >
                    2
                  </div>
                  {/* <div
                    className={`progress__circle js-circle ${
                      state.step === 2 ? progress : ""
                    }`}
                  >
                    3
                  </div> */}
                </div>
                <div className="wrap-login100 p-l-30 p-r-30 p-t-30 p-b-30">
                  <div className="text-center p-b-20" style={{ width: "100%" }}>
                    <img
                      src="http://kssc.org.sa/assets/images/LogoEn.png"
                      alt=""
                      style={{ width: "70%" }}
                    />
                  </div>

                  {state.step === 0 && (
                    <div id="step1" style={{ width: "100%" }}>
                      <form>
                        <span className="login100-form-title p-b-5">
                          {t("member")}
                        </span>
                        <div style={{ width: "100%" }}>
                          <div className="wrap-input100 validate-input m-t-10">
                            <input
                              className="input100"
                              type="text"
                              placeholder={t("ID")}
                              {...register("NationalID", {
                                required: {
                                  value: true,
                                  message: t("IDRequired"),
                                },
                                pattern: {
                                  value: /^[1|2]\d{9}$/,
                                  message: t("IDInvalid"),
                                },
                              })}
                            />

                            <span className="focus-input100"></span>
                            <span className="symbol-input100">
                              <span className="lnr lnr-license"></span>
                            </span>
                          </div>
                          {errors.NationalID &&
                            errors.NationalID.type == "required" && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {t("ID_Required")}
                              </p>
                            )}
                          {errors.NationalID &&
                            errors.NationalID.type == "pattern" && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {t("IDInvalid")}
                              </p>
                            )}
                          <div className="wrap-input100 validate-input m-t-10">
                            <input
                              className="input100"
                              type="text"
                              placeholder={t("Name_Required")}
                              {...register("Name", {
                                required: {
                                  value: true,
                                  message: t("NameRequired"),
                                },
                              })}
                            />
                            <span className="focus-input100"></span>
                            <span className="symbol-input100">
                              <span className="lnr lnr-user"></span>
                            </span>
                          </div>
                          {errors.Name && errors.Name.type == "required" && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {t("NameRequired")}
                            </p>
                          )}

                          <div className="wrap-input100 validate-input m-t-10">
                            <input
                              className="input100"
                              type="text"
                              placeholder={t("Email_Required")}
                              {...register("Email", {
                                required: {
                                  value: false,
                                  message: t("EmailRequired"),
                                },
                                pattern: {
                                  value:
                                    /[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-z0-9-]+\.)+[a-z]{2,6}$/,
                                  message: t("EmailInvalid"),
                                },
                              })}
                            />

                            <span className="focus-input100"></span>
                            <span className="symbol-input100">
                              <span className="lnr lnr-envelope"></span>
                            </span>
                          </div>
                          {errors.Email && errors.Email.type == "required" && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {t("EmailRequired")}
                            </p>
                          )}
                          {errors.Email && errors.Email.type == "pattern" && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {t("EmailInvalid")}
                            </p>
                          )}
                          <div className="wrap-input100 validate-input m-t-10">
                            <input
                              className="input100"
                              type="text"
                              placeholder={t("Mobile_Required")}
                              {...register("Mobile", {
                                required: {
                                  value: true,
                                  message: t("MobileRequired"),
                                },
                                pattern: {
                                  value: /^(\+9665|9665|05|5)[0-9]{8}$/,
                                  message: t("MobileInvalid"),
                                },
                              })}
                            />

                            <span className="focus-input100"></span>
                            <span className="symbol-input100">
                              <span className="lnr lnr-smartphone"></span>
                            </span>
                          </div>
                          {errors.Mobile &&
                            errors.Mobile.type == "required" && (
                              <p style={{ color: "red", fontSize: "12px" }}>
                                {t("MobileRequired")}
                              </p>
                            )}
                          {errors.Mobile && errors.Mobile.type == "pattern" && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {t("MobileInvalid")}
                            </p>
                          )}
                          <div
                            className={`${lngClass} wrap-input100 validate-input m-t-10`}
                          >
                            <Controller
                              name="DOB"
                              control={control}
                              defaultValue={null}
                              rules={{
                                required: {
                                  value: true,
                                  message: t("DOBRequired"),
                                },
                              }}
                              render={({ field }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  placeholder={t("DOB_Required")}
                                >
                                  <DesktopDatePicker
                                   open={openDOB}
                                   onOpen={() => setOpenDOB(true)}
                                   onClose={() => setOpenDOB(false)}
                                    placeholder="DOB"
                                    placeholderText={t("DOB")}
                                    views={["year", "month", "day"]}
                                    value={field.value}
                                    onChange={(e) => field.onChange(e)}
                                    className={classes.root}
                                    renderInput={(params) => (
                                      <TextField {...params} onClick={(e) => setOpenDOB(true)}/>
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </div>
                          {errors.DOB && errors.DOB.type == "required" && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {t("DOBRequired")}
                            </p>
                          )}
                        </div>
                        <div className="input_wrap p-t-16">
                          <ul>
                            <li>
                              <label className="radio_wrap">
                                <input
                                  type="radio"
                                  value="1"
                                  className="input_radio"
                                  {...register("GenderId", {
                                    required: {
                                      value: true,
                                      message: t("Gender"),
                                    },
                                  })}
                                />
                                <span>{t("Male")}</span>
                              </label>
                            </li>
                            <li>
                              <label className="radio_wrap">
                                <input
                                  type="radio"
                                  value="2"
                                  className="input_radio"
                                  {...register("GenderId", {
                                    required: {
                                      value: true,
                                      message: t("Gender"),
                                    },
                                  })}
                                />
                                <span>{t("Female")}</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <center>
                          {errors.GenderId &&
                            errors.GenderId.type == "required" && (
                              <p style={{ color: "red", marginTop: "4%" }}>
                                {t("Gender")}
                              </p>
                            )}
                        </center>
                        <div className="container-login100-form-btn p-t-25">
                          <button
                            className="login100-form-btn"
                            onClick={handleSubmit(onSkip)}
                          >
                            {t("Next")}
                            {i18n.language === "en" && (
                              <FontAwesomeIcon icon={faArrowRight} />
                            )}
                            {i18n.language === "ar" && (
                              <FontAwesomeIcon icon={faArrowLeft} />
                            )}
                          </button>
                          <div className="text-center w-full p-t-20">
                            <span className="txt1">{t("Account")}</span>
                            <Link
                              className="txt1 bo1 hov1"
                              to="/login"
                              style={{ textDecoration: "none", margin: "0 1%" }}
                            >
                              {t("login")}
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                  {state.step === 1 && (
                    <div id="step2" style={{ width: "100%" }}>
                      <div style={{ width: "100%" }}>
                        <span className="login100-form-title p-b-5">
                          {t("verifyOTP")}
                        </span>

                        <span
                          className="p-b-42"
                          style={{
                            color: "#ccc",
                            lineHeight: "1.2",
                            textTransform: "none",
                            width: "100%",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          {t("code")} <span>{localStorage.getItem("mobNum")}</span>
                        </span>
                        <div>
                          <form>
                            <div className="mb-6 text-center">
                              <div
                                id="otp"
                                className="flex justify-center wrap-input100 validate-input"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              ></div>

                              <OtpInput
                                value={OTP}
                                onChange={handleChange}
                                numInputs={4}
                                shouldAutoFocus="false"
                                isInputSecure="false"
                                containerStyle={"otp-div"}
                                inputStyle={"otp-input"}
                              />
                              {error && (
                                <p style={{ color: "red" }}>
                                  {t("OTPRequired")}
                                </p>
                              )}
                            </div>
                            <div className="container-login100-form-btn p-t-25">
                              <div className="row">
                                <div className="col-md-12">
                                  <button
                                    type="button"
                                    className="login100-form-btn"
                                    disabled={OTP.length !== 4}
                                    onClick={submitRegistration}
                                  >
                                    {t("verify")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="text-center w-full p-t-40">
                          <span className="txt1">{t("Receive")}</span>
                          <Link
                            className="txt1 bo1 hov1"
                            onClick={resend}
                            to="#"
                            style={{
                              textDecoration: "none",
                              margin: "0 2% 0 1%",
                            }}
                          >
                            {t("Resend")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {state.step === 2 && (
                    <div id="step3" style={{ width: "100%" }}>
                      <form className="login100-form validate-form">
                        <span className="login100-form-title p-b-5">
                          {t("SubscriberTypes")}
                        </span>
                        <div className="main-container" >
                          <div
                            className={
                              i18n.language === "en"
                                ? "membership"
                                : "membershipar"
                            }
                          >
                            {subscriberTypes?.map((list, index) => {
                              return (
                                <div className="reg" key={list.MembershipTypeId}>
                                  <input
                                    type="radio"
                                    id={`controlReg_${index}`}
                                    value={list.MembershipTypeId}
                                    {...register("MembershiptypeId", {
                                      required: {
                                        value: true,
                                        message: t("Membershiptype"),
                                      },
                                    })}
                                  />
                                  <label htmlFor={`controlReg_${index}`}>
                                    <div className="card card-1">
                                      <div className="card__icon">
                                        <div className="row">
                                          <div
                                            className={
                                              i18n.language === "en"
                                                ? "col-lg-4 borderRight"
                                                : "col-lg-4 borderLeft"
                                            }
                                          >
                                            <span
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              SR. {list.Fees}
                                            </span>
                                          </div>
                                          <div className="col-lg-6">
                                            <p>
                                              <b>
                                                {i18n.language === "en" &&
                                                  list.NameEng}
                                                {i18n.language === "ar" &&
                                                  list.NameAra}
                                              </b>
                                            </p>
                                            <p style={{ fontSize: "13px" }}>
                                              <b>Duration </b> :{" "}
                                              <span>
                                                {i18n.language === "en" &&
                                                  list.ValidityNameEng}
                                                {i18n.language === "ar" &&
                                                  list.ValidityNameAra}
                                              </span>
                                            </p>
                                          </div>
                                          <div
                                            className="col-lg-2"
                                            style={{
                                              textAlign: "right",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <a href="#">
                                              {" "}
                                              <p className="card__exit">
                                                {i18n.language === "en" && (
                                                  <FontAwesomeIcon
                                                    icon={faArrowRight}
                                                  />
                                                )}
                                                {i18n.language === "ar" && (
                                                  <FontAwesomeIcon
                                                    icon={faArrowLeft}
                                                  />
                                                )}
                                              </p>{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                          {errors.MembershiptypeId &&
                            errors.MembershiptypeId.type == "required" && (
                              <p style={{ color: "red", marginTop: "4%" }}>
                                {t("Membershiptype")}
                              </p>
                            )}

                          <div
                            className={`${lngClass} wrap-input100 validate-input m-b-16`}
                          >
                            <Controller
                              name="StartDate"
                              control={control}
                              defaultValue={null}
                              rules={{
                                required: {
                                  value: true,
                                  message: t("StartDateRequired"),
                                },
                              }}
                              render={({ field }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <label style={{ padingTop: "5px" }}>
                                    Start Date
                                  </label>
                                  <DesktopDatePicker
                                    views={["year", "month", "day"]}
                                    value={field.value}
                                    onChange={(e) => field.onChange(e)}
                                    className={classes.root}
                                    minDate={new Date()}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                            {errors.StartDate &&
                              errors.StartDate.type == "required" && (
                                <p style={{ color: "red" }}>
                                  {t("StartDateRequired")}
                                </p>
                              )}
                          </div>
                          {endDate && <p>End date is: {endDate}</p>}
                        </div>

                        <div className="container-login100-form-btn p-t-25">
                          <div className="row" style={{width:"100%"}}>
                            <div className="col-md-5" style={{paddingRight: "0px", paddingLeft: "0px"}}>
                              <button
                                className="previous"
                                onClick={() =>
                                  dispatch({ type: ACTIONS.previous })
                                }
                              >
                                {i18n.language === "en" && (
                                  <FontAwesomeIcon icon={faArrowLeft} />
                                )}
                                {i18n.language === "ar" && (
                                  <FontAwesomeIcon icon={faArrowRight} />
                                )}
                                {t("Previous")}
                              </button>
                            </div>
                            
                            <div className="col-md-7">
                            <div className="row" style={{justifyContent:"end"}}>
                            <div className="col-" style={{paddingRight:"7px", paddingTop:"4px"}}>
                            <Link to="#" style={{fontSize: "19px"}}
                                className="txt1 bo1 hov1"
                                onClick={() =>
                                  onSkip()
                                }
                              >
                                {t("Skip")}
                              </Link>
                            </div>
                            <div className="col-">
                            <button
                                className="login100-form-btn"
                                onClick={handleSubmit(onSubmit)}
                                // {() => dispatch({ type: ACTIONS.next })}
                              >
                                {t("GetOTP")}
                                {i18n.language === "en" && (
                                  <FontAwesomeIcon icon={faArrowRight} />
                                )}
                                {i18n.language === "ar" && (
                                  <FontAwesomeIcon icon={faArrowLeft} />
                                )}
                              </button>
                            </div>
                            </div>
                            
                            
                            </div>
                           
                          </div>
                        </div>
                      </form>
                    </div>
                  )} */}
                  
                </div>
              </main>
            </div>
          </div>
        </AuthLayout>
      )
      ) : (
        navigate("/main")
      )}
    </div>
  );
};

export default Registration;


