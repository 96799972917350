import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AuthLayout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div
      style={{
        direction: i18n.language == "en" ? "ltr" : "rtl",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: "8px",
          top: "10px",
          zIndex: 99,
          paddingTop: "17px",
          textAlign: "right",
        }}
      >
        {/* <div className="switch">
          <input
            id="language-toggle"
            className="check-toggle check-toggle-round-flat"
            type="checkbox"
          />
          <label htmlFor="language-toggle"></label>
          <button id="arButton" className={i18n.language == 'ar' ? 'on' : 'off'}
        
           onClick={() => changeLanguage("ar")}>
            AR
          </button>
          <button id="enButton" className= {i18n.language == 'en' ? 'on' : 'off'}
         
          onClick={() => changeLanguage("en")}>
            EN
          </button>
        </div> */}
        <div className="example">
          <div className="col-sm-5">
            {i18n.language == "en" && (
              <button
                type="button"
                className="btn-lg btn-toggle"
                data-toggle="button"
                aria-pressed="false"
                autoComplete="off"
                onClick={() => changeLanguage("ar")}
              >
                <div className="handle"></div>
              </button>
            )}
            {i18n.language == "ar" && (
              <button
                type="button"
                className="btn-lg btn-toggle active"
                data-toggle="button"
                aria-pressed="true"
                autoComplete="off"
                onClick={() => changeLanguage("en")}
              >
                <div className="handle"></div>
              </button>
            )}
          </div>
        </div>
      </div>

      {children}
    </div>
  );
};

export default AuthLayout;
