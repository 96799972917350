
import React, { useEffect, useState, useReducer, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import CloseIcon from "@mui/icons-material/Close";
import "./custom.scss";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import maleImg from "../../images/male.jpg";
import femaleImg from "../../images/female.jpg";
import { makeStyles } from "@mui/styles";
import authContext from "../../auth-context";
import { API } from "../../Constants/Global";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "25px",
  },
  "& .MuiDialogActions-root": {
    padding: "10px",
  },
  "& .MuiInputLabel-root": {
    color: "#43425d",
    fontWeight: "bold",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    width: "360px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const { t, i18n } = useTranslation();
  return (
    <DialogTitle
      className={i18n.language === "en" ? "ltr" : "rtl"}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: i18n.language === "en" ? 8 : "auto",
            left: i18n.language === "en" ? "auto" : 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
          }}
        >
        </CloseIcon>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const defaultValues = {
  account_name: "",
};
const FreezeRequestPopup = (props) => {

  const { t, i18n } = useTranslation();
  const lng = localStorage.getItem("i18nextLng");
  const tkn = localStorage.getItem("token");
  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  const [openAlert, setOpenAlert] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [isLoading, setIsLoading] = useState(false);
  const { token, userLogin, isLoggedIn } = useContext(authContext);
  const [openFreezeStartDate, setOpenFreezeStartDate] = useState(false);
  const [openFreezeEndDate, setOpenFreezeEndDate] = useState(false);

  function convert(str) {
    const date = new Date(str);
    const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  const minFreezeStartDate = new Date();
  let maxFreezeEndDate = new Date();
  maxFreezeEndDate.setDate(maxFreezeEndDate.getDate() + user?.MaxDiff);

  const handleClose = () => {
    props.onCloseModal(false);
  };


  const _FreezeStartDate = watch("FreezeStartDate", null);
  const FreezeStartDate = convert(_FreezeStartDate);

  const _FreezeEndDate = watch("FreezeEndDate", null);
  const FreezeEndDate = convert(_FreezeEndDate);

  const submitFreeze = () => {
    setIsLoading(true);
    const subscriberId = JSON.parse(localStorage.getItem("user"))?.SubscriberId;
    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    const subData = {
      FreezeStartDate: FreezeStartDate,
      FreezeEndDate: FreezeEndDate

    };

    axios
      .post(
        `${API}subscriber/freezerequest`,
        subData,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setIsLoading(false);
          toast.success(response.data.Message);
          handleClose();
        } else {
          toast.error(response.data.Message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      });
  };



  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
        Width: "800px",
      }}
    >
      <BootstrapDialog
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        style={{
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
        fullWidth={true}
        maxWidth={"md"}
      >
        {isLoading && (
          <div className="loader-popup">
            <div className="circle-loader" style={{ top: "50%" }}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerclassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
              padding: "25px",
            },
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "white",
              },
            },
            error: {
              duration: 5000,
              theme: {
                primary: "red",
                secondary: "black",
              },
            },
          }}
        />
        <BootstrapDialogTitle
          style={{ borderBottom: "1px solid #ccc" }}
          id="renewal-popup"
          onClose={handleClose}
        >
          <div>
            <h3>{t("FreezeMembership")}</h3>
          </div>
        </BootstrapDialogTitle>
        <DialogContent>
          <div id="" style={{ width: "100%" }}>
            <form>
              <div className="pl-lg-0" style={{ paddingTop: "10px" }}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">


                      <div className={`${lngClass} "validate-input m-t-10`} style={{ width: "fit-content" }}>
                        <Controller
                          name="FreezeStartDate"
                          control={control}
                          defaultValue={null}
                          rules={{
                            required: {
                              value: true,
                              message: t("StartDateRequired"),
                            },
                          }}
                          render={({ field }) => (
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                            >
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                {t("FreezeStartDate")} &nbsp;{" "}
                                <font color="red">*</font>
                              </label>
                              <DesktopDatePicker
                                open={openFreezeStartDate}
                                onOpen={() => setOpenFreezeStartDate(true)}
                                onClose={() => setOpenFreezeStartDate(false)}
                                views={["year", "month", "day"]}
                                value={field.value}
                                onChange={(e) => field.onChange(e)}
                                className="form-control form-control-alternative"
                                minDate={minFreezeStartDate}
                                renderInput={(params) => (
                                  <TextField {...params} onClick={(e) => setOpenFreezeStartDate(true)} defaultValue={null} />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                        {errors.FreezeStartDate && errors.FreezeStartDate.type == "required" && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                            }}
                          >
                            {t("StartDateRequired")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">

                      <div className={`${lngClass} "validate-input m-t-10`} style={{ width: "fit-content" }}>
                        <Controller
                          name="FreezeEndDate"
                          control={control}
                          defaultValue={null}
                          rules={{
                            required: {
                              value: true,
                              message: t("EndDateRequired"),
                            },
                          }}

                          render={({ field }) => (
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                            >
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                {t("FreezeEndDate")} &nbsp;{" "}
                                <font color="red">*</font>
                              </label>
                              <DesktopDatePicker
                                open={openFreezeEndDate}
                                onOpen={() => setOpenFreezeEndDate(true)}
                                onClose={() => setOpenFreezeEndDate(false)}
                                views={["year", "month", "day"]}
                                value={field.value}
                                onChange={(e) => field.onChange(e)}
                                className="form-control form-control-alternative"
                                minDate={_FreezeStartDate}
                                maxDate={maxFreezeEndDate}
                                renderInput={(params) => (
                                  <TextField {...params} onClick={(e) => setOpenFreezeEndDate(true)} />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                        {errors.FreezeEndDate && errors.FreezeEndDate.type == "required" && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                            }}
                          >
                            {t("EndDateRequired")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <div>
            <>
              <button className="button"
                disabled={(_FreezeEndDate != null && _FreezeStartDate != null) ? false : true}
                onClick={() => submitFreeze()}
              >
                {t("submit")}
              </button>
            </>
          </div>
        </DialogActions>
      </BootstrapDialog>
      )
    </div>
  );
};

export default FreezeRequestPopup;
