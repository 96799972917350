import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AppointmentClinic from "./pages/appPages/AppointmentClinic";
import Clinic from "./pages/appPages/Clinic";
import Events from "./pages/appPages/Events";
import Main from "./pages/appPages/Main";
import Profile from "./pages/appPages/Profile";
import SportsExcercise from "./pages/appPages/SportsExcercise";
import ScheduleSports from "./pages/appPages/ScheduleSports";
import Empowerment from "./pages/appPages/Empowerment";
import Sports from "./pages/appPages/Sports";
import Login from "./pages/authPages/Login";
import Registration from "./pages/authPages/Registration";
import Verification from "./pages/authPages/Verification";
import authContext from "./auth-context";
import PaymentResponse from "./pages/appPages/PaymentResponse";

function App() {
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);

  return (
    <div className="App">
      <Routes>
        {!token && (
          <>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/verify" element={<Verification />} />
          </>
        )}
        {token && (
          <>
            <Route path="/main" element={<Main />} />
            <Route path="/sports" element={<Sports />} />
            <Route path="/clinic" element={<Clinic />} />
            <Route path="/clinic/booking" element={<AppointmentClinic />} />
            <Route path="/sports/booking" element={<SportsExcercise />} />
            <Route path="/sports/booking/list/:Exercise" element={<ScheduleSports />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/events" element={<Events />} />
            <Route path="/empowerment" element={<Empowerment />} />
            <Route path="/paymentresponse/:ReferenceId/:CheckoutID" element={<PaymentResponse />} />
          </>
        )}
        <Route
          path="*"
          element={<Navigate to={token ? "/main" : "/login"} />}
        />
      </Routes>
    </div>
  );
}

export default App;
