import { LOGIN, LOGOUT } from "../Actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        isAuthenticated: true,
      };
    }

    case LOGOUT: {
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
