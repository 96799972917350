import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { lighten, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import CloseIcon from "@mui/icons-material/Close";
import "./custom.scss";
import maleImg from "../../images/male.jpg";
import femaleImg from "../../images/female.jpg";
import { API } from "../../Constants/Global";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "25px",
  },
  "& .MuiDialogActions-root": {
    padding: "10px",
  },
  "& .MuiInputLabel-root": {
    color: "#43425d",
    fontWeight: "bold",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    width: "360px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  const { t, i18n } = useTranslation();
  return (
    <DialogTitle
      className={i18n.language === "en" ? "ltr" : "rtl"}
      sx={{ m: 0, p: 2 }}
      {...other}
    // style={{
    //   direction: i18n.language === "en" ? "ltr" : "rtl",
    // }}
    >
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: i18n.language === "en" ? 8 : "auto",
            left: i18n.language === "en" ? "auto" : 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
          }}
        >
          {/* <Icon>
            <CloseIcon />
          </Icon> */}
        </CloseIcon>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const defaultValues = {
  account_name: "",
};
const SportsPopup = (props) => {
  const { t, i18n } = useTranslation();
  const lng = localStorage.getItem("i18nextLng");
const tkn = localStorage.getItem("token");
  const service = props.service;
  const price = service?.Price;
  const nameOfService =
    i18n.language === "en" ? service?.NameEng : service?.NameAra;
  const subService =
    i18n.language === "en" ? service?.SubNameEng : service?.SubNameAra;
  const sessions = service?.Sessions;
  const [roster, setRoster] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [ClassesByDates, setClassesByDates] = useState([]);
  //const [SessionsByDate, setSessionsByDate] = useState([]);
  const [SessionsByDate, setSessionsByDate] = useState({ list: [], change: false });
  const d = new Date();
  let curr_date = d.getDate();
  let curr_month = d.getMonth();
  curr_month++;
  let curr_year = d.getFullYear();

  if (curr_month < 10)
    curr_month = curr_month;
  if (curr_date < 10)
    curr_date = curr_date;
  let date = curr_month + "/" + curr_date + "/" + curr_year;
  let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let years = [2021, 2022, 2023, 2024];
  const [month, setMonth] = useState(curr_month);
  const [year, setYear] = useState(curr_year);
  const [calendarDate, setCalendarDate] = useState(null);
  const [selectedClasstimings, setSelectedClasstimings] = useState([]);
  const [selectedClassDatetimings, setSelectedClassDatetimings] = useState([]);
  const [error, setError] = useState('');
  const subscriberId = JSON.parse(localStorage.getItem("user"))?.SubscriberId;
  const user = JSON.parse(localStorage.getItem("user"));
  


  const selectClassTimings = (item) => () => {
    var forSelection = null;
    if (selectedClasstimings.indexOf(item.ClassRosterId_Date_Id) > -1) {
      forSelection = false;
    }
    else {
      forSelection = true;
    }

    if (forSelection) {
      if (selectedClasstimings.length <= sessions - 1) {
        setSelectedClasstimings((oldArray) => [...oldArray, item.ClassRosterId_Date_Id]);
        let _existClassDatetimings = selectedClassDatetimings.find(o => o.ClassRosterId_Date_Id === item.ClassRosterId_Date_Id);
        if (_existClassDatetimings != null) {
          setSelectedClassDatetimings((current) =>
            current.filter((selectedClassDatetimings) => selectedClassDatetimings.ClassRosterId_Date_Id === item.ClassRosterId_Date_Id));
        }
        else {
          setSelectedClassDatetimings(current => [...current, item]);
        }
      }
      else {
        toast.error('You cannot select more than ' + sessions + '  session(s)');
      }
    }
    else {
      var idx = selectedClasstimings.indexOf(item.ClassRosterId_Date_Id);
      if (idx > -1) {
        selectedClasstimings.splice(idx, 1);
        setSelectedClasstimings((oldArray) => [...oldArray]);
        let _existClassDatetimings = selectedClassDatetimings.find(o => o.ClassRosterId_Date_Id === item.ClassRosterId_Date_Id);
        if (_existClassDatetimings != null) {
          selectedClassDatetimings.splice(selectedClassDatetimings.indexOf(item.ClassRosterId_Date_Id), 1);
          setSelectedClassDatetimings((oldArray) => [...oldArray]);
        }
        else {
          setSelectedClassDatetimings(current => [...current, item]);
        }
      }
    }

  };

  function getSessionsForDates(obj) {
    setSessionsByDate((prev) => ({ ...prev, list: obj.Working_Days, change: true }));
  }

  function increaseMonths(m, y) {
    let _month = 0;
    let _year = 0;
    if (m == 12) {
      _month = 1;
      _year = y + 1;
      setMonth(_month);
      setYear(_year);
    }
    else {
      _month = m + 1;
      _year = y;
      setMonth(_month);
      setYear(_year);
    }
  }

  function decreaseMonths(m, y) {
    let _month = 0;
    let _year = 0;
    if (m == 1) {
      _month = 12;
      _year = y - 1;
      setMonth(_month);
      setYear(_year);
    }
    else {
      _month = m - 1;
      _year = y;
      setMonth(_month);
      setYear(_year);
    }
  }


  const ACTIONS = {
    next: "next",
    previous: "previous",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const formReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.next:
        return { step: state.step + 1 };
      case ACTIONS.previous:
        return { step: state.step - 1 };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formReducer, { step: 0 });

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  function convert(str) {
    const date = new Date(str);
    const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  useEffect(() => {
    setIsLoading(true);
    const lng = localStorage.getItem("i18nextLng");
    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": lng === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}sports/getcalendarwithsportssessions?Month=${month}&Year=${year}&ServiceId=${service?.ServiceId}&SelectedClasses=&EndDate=${user?.EndDate}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setClassesByDates(response.data.Data);
        } else {
          toast.error(response.data.Message);
        }
        setIsLoading(false);
        setSessionsByDate((prev) => ({ ...prev, list: [], change: false }));
        setCalendarDate(null);
      })
      .catch((error) => console.log(error));
  }, [month]);


  useEffect(() => {
    setIsLoading(true);
  }, []);


  const handleClose = () => {
    props.onCloseModal(false);
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [icon, setIcon] = useState("mail_outline");

  function handleCloseModal(event, data) {
    setOpenAlert(false);
  }

  const navigate = useNavigate();

  const book = () => {
    const subscriberId = JSON.parse(
      localStorage.getItem("user") != "undefined" &&
      localStorage.getItem("user")
    )?.SubscriberId;

    const lng = localStorage.getItem("i18nextLng");
    const tkn = localStorage.getItem("token");
    const ServiceID = service?.ServiceId;
    const subData = {
      SubscriberId: subscriberId,
      ServiceId: ServiceID,
      SelectedClassSessions: selectedClasstimings,
    };
    setIsLoading(true);
    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": lng === "ar" ? "Ar" : "En",
      },
    };
    axios
      .post(
        `${API}sports/subscriberbooksportspay`,
        subData,
        config
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.isSuccess) {
          if (response.data.PaymentGatewayInfo != null) {
            if (response.data.PaymentGatewayInfo.result.code === "000.200.100") {
              window.location = response.data.PaymentGatewayUrl;
            }
          }
          else {
            toast.success(response.data.Message);
            navigate("/sports");
          }

        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const lngClass = i18n.language === "en" ? "ltr" : "rtl";

  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
        Width: "700px",
      }}
    >
      <BootstrapDialog className="modelheight"
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        style={{
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
        fullWidth={true}
        maxWidth={"sm"}

      >
        {isLoading && (
          <div className="loader-popup">
            <div className="circle-loader" style={{ top: "50%" }}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerclassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
              padding: "25px",
            },
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "white",
              },
            },
            error: {
              duration: 5000,
              theme: {
                primary: "red",
                secondary: "black",
              },
            },
          }}
        />
        <BootstrapDialogTitle
          style={{ borderBottom: "1px solid #ccc" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <div>
            {i18n.language === "en" ? service?.NameEng : service?.NameAra}
          </div>
        </BootstrapDialogTitle>

        <DialogContent className="modelFooter">
          <Helmet>
            <script
              async="true"
              src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"
            ></script>
            <script async="true" src="/script/popupFormJQuery.js"></script>
          </Helmet>
          {state.step === 0 && (
            <section
              className={i18n.language === "en" ? "ltr" : "rtl"}
              style={{ maxWidth: "100%" }}
            >
              <div style={{ paddingTop: "10px" }}>
                <p>Please Select  {sessions} Session(s) <span style={{ color: "red" }}>*</span></p>
                {Object.keys(selectedClasstimings).length > 0 && Object.keys(selectedClasstimings).length == sessions && (
                  <p><span style={{ color: "green" }}>You have selected {sessions} session(s)</span></p>
                )}
                {/* <pre>{JSON.stringify(selectedClasstimings, null, 2)}</pre> */}
              </div>
              <div className="calendar-container">
                <div className="calendar-header" style={{ display: "flex", width: "100%", justifyContent: "space-between", paddingRight: "10px", paddingLeft: "10px", paddingBottom: "10px" }}>
                  {i18n.language === "en" ? (
                    <i onClick={() => { decreaseMonths(month, year) }}
                      className="mdi mdi mdi-chevron-left"
                      style={{ float: "end", fontSize: "25px" }}
                    ></i>
                  ) : (
                    <i onClick={() => { increaseMonths(month, year) }}
                      className="mdi mdi mdi-chevron-right"
                      style={{ float: "end", fontSize: "25px" }}
                    ></i>
                  )}
                  <h1>
                    {months[month - 1]}, {year}
                  </h1>
                  {i18n.language === "en" ? (
                    <i onClick={() => { increaseMonths(month, year) }}
                      className="mdi mdi mdi-chevron-right"
                      style={{ float: "end", fontSize: "25px" }}
                    ></i>
                  ) : (
                    <i onClick={() => { decreaseMonths(month, year) }}
                      className="mdi mdi mdi-chevron-left"
                      style={{ float: "end", fontSize: "25px" }}
                    ></i>
                  )}
                </div>
                <div className="calendar">
                  <span className="day-name">{t("Mon")}</span><span className="day-name">{t("Tue")}</span><span className="day-name">{t("Wed")}</span><span className="day-name">{t("Thu")}</span><span className="day-name">{t("Fri")}</span><span className="day-name">{t("Sat")}</span><span className="day-name">{t("Sun")}</span>
                  {ClassesByDates?.map((obj, index) => {
                    return (
                      <div
                        className={JSON.stringify(selectedClasstimings)?.includes(`~${obj?.Date_Id}`) ? 'selected-date' : 'decoration'}
                        key={index}>
                        {obj?.Is_Past && (
                          <div className="day--disabled">
                            {obj.Date_Number > 0 && <div className="" style={{ textAlign: "center" }}>
                              {obj.Date_Number}
                            </div>}
                          </div>
                        )}

                        {!obj?.Is_Past && (
                          <>
                            {(obj.Date_Number > 0 && obj?.Working_Days?.length == 0) && <div className="" style={{ textAlign: "center", paddingTop: "5px" }}>
                              {obj.Date_Number}
                            </div>}
                            {(obj.Date_Number > 0 && obj?.Working_Days?.length > 0) &&

                              <div className={calendarDate == obj.Date_Id ? 'active-session' : ''} style={{ textAlign: "center", cursor: "pointer", paddingTop: "5px" }}>
                                <div
                                  className="radio-btn "
                                  onClick={() => {
                                    setCalendarDate(obj.Date_Id);
                                    getSessionsForDates(obj);
                                  }}
                                >

                                  {obj.Date_Number}
                                  <p><span className="availabel_Seesions"></span></p>
                                </div>
                              </div>
                            }
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="" style={{ width: "100%", maxHeight: "100%", overflowY: "visible" }}>
                  {SessionsByDate?.list?.map((x, _i) => {
                    return (
                      <>
                        <div key={x.ClassRosterId_Date_Id} onClick={selectClassTimings(x)}
                          className={JSON.stringify(selectedClasstimings)?.includes(x.ClassRosterId_Date_Id) ? 'active-session' : ''}>
                          <div className="col-lg-12 chckboxstyle">
                            <div className="row rowCustomepaddingMargin" >
                              <div className="col-lg-12 borderRightCheckbox" style={{ paddingRight: "0px" }}>
                                <p style={{ marginBottom: "2px" }}><b style={{ fontWight: "600" }}>
                                  <i className="mdi mdi-timer"></i> &nbsp;
                                  {x.StartTime} - {x.EndTime}</b>({x.ClassStrength})
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>

              </div>
            </section>
          )}
          {state.step === 1 && (
            <section className={i18n.language === "en" ? "ltr" : "rtl"}>
              <div className="receipt">
                <header className="receipt__header">
                  <p
                    className="receipt__title"
                    style={{ paddingTop: "10px", fontSize: "1.2rem" }}
                  >
                    {t("Summary")}
                  </p>
                </header>
                <dl className="receipt__list">
                  <div className="receipt__list-row">
                    <dt className="receipt__item">{t("ServiceName")} </dt>
                    <dd className="receipt__cost">
                      {nameOfService}
                    </dd>
                  </div>
                  <div className="receipt__list-row">
                    <dt className="receipt__item">{t("Sessions")}</dt>
                    <dd className="receipt__cost">{sessions}</dd>
                  </div>
                  <div className="receipt__list-row">
                    <dt className="receipt__item">{t("AppointmentDate")}</dt>
                    <dd className="receipt__cost">
                      <div style={{ display: "inline-grid" }}>
                        {selectedClassDatetimings?.map((s, _i) => {
                          return (
                            <span>{s.Date} ({s.StartTime} - {s.EndTime}) </span>
                          )
                        })}
                      </div>
                    </dd>
                  </div>
                  <div className="receipt__list-row receipt__list-row--total">
                    <dt className="receipt__item">{t("grandTotal")}</dt>
                    <dd className="receipt__cost">SAR. {price}</dd>
                  </div>
                </dl>
              </div>
            </section>
          )}
        </DialogContent>
        <DialogActions style={{ overflowX: "hidden" }}>
          <div style={{ display: "contents" }}>
            {state.step !== 0 && (
              <button
                className="buttonPre"
                onClick={() => dispatch({ type: ACTIONS.previous })}
              >
                {i18n.language === "en" ? (
                  <i
                    className="mdi mdi mdi-chevron-left"
                    style={{ float: "end" }}
                  ></i>
                ) : (
                  <i
                    className="mdi mdi mdi-chevron-right"
                    style={{ float: "end" }}
                  ></i>
                )}
                {t("Previous")}
              </button>
            )}
            {state.step !== 1 ? (
              <button
                disabled={Object.keys(selectedClasstimings).length == 0}
                className="button"
                onClick={() => dispatch({ type: ACTIONS.next })}
              >
                {t("Next")}
                {i18n.language === "en" ? (
                  <i
                    className="mdi mdi mdi-chevron-right"
                    style={{ float: "end" }}
                  ></i>
                ) : (
                  <i
                    className="mdi mdi mdi-chevron-left"
                    style={{ float: "end" }}
                  ></i>
                )}
              </button>
            ) : (
              <button onClick={book} className="button">
                {t("Book")}
              </button>
            )}
          </div>
        </DialogActions>
      </BootstrapDialog>

    </div>
  );
};

export default SportsPopup;
