import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";
import CloseIcon from "@mui/icons-material/Close";
import "./custom.scss";
import { API } from "../../Constants/Global";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "25px",
  },
  "& .MuiDialogActions-root": {
    padding: "10px",
  },
  "& .MuiInputLabel-root": {
    color: "#43425d",
    fontWeight: "bold",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    width: "360px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const { t, i18n } = useTranslation();
  return (
    <DialogTitle
      className={i18n.language === "en" ? "ltr" : "rtl"}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: i18n.language === "en" ? 8 : "auto",
            left: i18n.language === "en" ? "auto" : 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
          }}
        >
        </CloseIcon>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const defaultValues = {
  account_name: "",
};
const CalendarPopup = (props) => {
  const { t, i18n } = useTranslation();
  //const { appointment } = props;
  const [appointment, setAppointment] = useState(props?.appointment);
  const service = i18n.language === "en" ? appointment.ServiceNameEng : appointment.ServiceNameAra;
  const therapistName = appointment?.TherapistName;
  const [isLoading, setIsLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const subscriberId = user?.SubscriberId;
  const ACTIONS = {
    next: "next",
    previous: "previous",
  };


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const formReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.next:
        return { step: state.step + 1 };
      case ACTIONS.previous:
        return { step: state.step - 1 };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formReducer, { step: 0 });

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });


  const handleClose = () => {
    props.onCloseModal(false);
  };


  const [openAlert, setOpenAlert] = useState(false);
  const [icon, setIcon] = useState("mail_outline");

  function handleCloseModal(event, data) {
    setOpenAlert(false);
  }

  const navigate = useNavigate();
  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  const sessions = appointment?.ServiceSessions;
  const [SessionsByDate, setSessionsByDate] = useState({ list: [], change: false, Is_Past: null });
  const [selectedClasstimings, setSelectedClasstimings] = useState([]);
  const [selectedClassDatetimings, setSelectedClassDatetimings] = useState([]);
  const [ClassesByDates, setClassesByDates] = useState([]);
  const [error, setError] = useState('');
  const d = new Date();
  let curr_date = d.getDate();
  let curr_month = d.getMonth();
  curr_month++;
  let curr_year = d.getFullYear();

  if (curr_month < 10)
    curr_month = curr_month;
  if (curr_date < 10)
    curr_date = curr_date;
  let date = curr_month + "/" + curr_date + "/" + curr_year;
  let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let years = [2021, 2022, 2023, 2024];
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [calendarDate, setCalendarDate] = useState(null);

  const lng = localStorage.getItem("i18nextLng");
  const token = localStorage.getItem("token");
  let config = {
    headers: {
      APICODE: token,
      "Accept-Language": lng === "ar" ? "Ar" : "En",
    },
  };

  function getSessionsForDates(obj) {
    setSessionsByDate((prev) => ({ ...prev, list: obj?.Slot_Days, change: true, Is_Past: obj?.Is_Past }));
  }

  const selectClassTimings = (item, is_past) => () => {
    debugger;
    if (appointment?.AppointmentStatusId != 2) {
      return;
    }
    if (is_past) {
      return;
    }

    if (!item.IsSelected) {
      var forSelection = null;
      if (selectedClasstimings.indexOf(item.Date_SPSIId) > -1) {
        forSelection = false;
      }
      else {
        forSelection = true;
      }

      if (forSelection) {
        if (selectedClasstimings.length <= sessions - 1) {
          setSelectedClasstimings((oldArray) => [...oldArray, item.Date_SPSIId]);
          let _existClassDatetimings = selectedClassDatetimings.find(o => o.Date_SPSIId === item.Date_SPSIId);
          if (_existClassDatetimings != null) {
            setSelectedClassDatetimings((current) =>
              current.filter((selectedClassDatetimings) => selectedClassDatetimings.Date_SPSIId === item.Date_SPSIId));
          }
          else {
            setSelectedClassDatetimings(current => [...current, item]);
          }
        }
        else {
          toast.error('You cannot select more than ' + sessions + '  session(s)');
        }
      }
      else {
        var idx = selectedClasstimings.indexOf(item.Date_SPSIId);
        if (idx > -1) {
          selectedClasstimings.splice(idx, 1);
          setSelectedClasstimings((oldArray) => [...oldArray]);
          let _existClassDatetimings = selectedClassDatetimings.find(o => o.Date_SPSIId === item.Date_SPSIId);
          if (_existClassDatetimings != null) {
            selectedClassDatetimings.splice(selectedClassDatetimings.indexOf(item.Date_SPSIId), 1);
            setSelectedClassDatetimings((oldArray) => [...oldArray]);
          }
          else {
            setSelectedClassDatetimings(current => [...current, item]);
          }
        }
      }


    }
    else {
      confirmAlert({
        title: 'Confirmation',
        message: item.Date + ' ' + item.SlotFrom + ' - ' + item.SlotTo + ' Slot is booked, are you sure you want to cancel this booking?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              setIsLoading(true);
              axios
                .get(
                  `${API}appointment/cancelappointmentslots?AppointmentId=${appointment?.AppointmentId}&Date_SPSIId=${item.Date_SPSIId}&SubscriberId=${subscriberId}`,
                  config
                )
                .then((response) => {
                  if (response.data.isSuccess) {
                    var idx = selectedClasstimings.indexOf(item.Date_SPSIId);
                    if (idx > -1) {
                      selectedClasstimings.splice(idx, 1);
                      setSelectedClasstimings((oldArray) => [...oldArray]);
                      let _existClassDatetimings = selectedClassDatetimings.find(o => o.Date_SPSIId === item.Date_SPSIId);
                      if (_existClassDatetimings != null) {
                        setSelectedClassDatetimings((current) =>
                          current.filter((selectedClassDatetimings) => selectedClassDatetimings.Date_SPSIId === item.Date_SPSIId));
                      }
                      else {
                        setSelectedClassDatetimings(current => [...current, item]);
                      }
                    }
                    toast.success(response.data.Message);
                    LoadCalendar();
                  } else {
                    toast.error(response.data.Message);
                  }
                  //setIsLoading(false);
                })
                .catch((error) => console.log(error));
            }
          },
          {
            label: 'No',
            onClick: () => { console.log('Click No') }
          }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false
      });
    }
  };

  function increaseMonths(m, y) {
    let _month = 0;
    let _year = 0;
    if (m == 12) {
      _month = 1;
      _year = y + 1;
      setMonth(_month);
      setYear(_year);
    }
    else {
      _month = m + 1;
      _year = y;
      setMonth(_month);
      setYear(_year);
    }
    setIsLoading(true);
    axios
      .get(
        `${API}appointment/getdaysandslotspermonth?Month=${_month}&Year=${_year}&UserId=${appointment?.UserId}
      &Date_SPSIIds=${appointment?.Date_SPSIIds}&EndDate=${user?.EndDate}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setClassesByDates(response.data?.Data);
        } else {
          toast.error(response.data.Message);
        }
        setIsLoading(false);
        setSessionsByDate((prev) => ({ ...prev, list: [], change: false, Is_Past: null }));
        setCalendarDate(null);
      })
      .catch((error) => {
        setIsLoading(false);
      });

  }

  function decreaseMonths(m, y) {
    let _month = 0;
    let _year = 0;
    if (m == 1) {
      _month = 12;
      _year = y - 1;
      setMonth(_month);
      setYear(_year);
    }
    else {
      _month = m - 1;
      _year = y;
      setMonth(_month);
      setYear(_year);
    }

    setIsLoading(true);
    axios
      .get(
        `${API}appointment/getdaysandslotspermonth?Month=${_month}&Year=${_year}&UserId=${appointment?.UserId}
      &Date_SPSIIds=${appointment?.Date_SPSIIds}&EndDate=${user?.EndDate}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setClassesByDates(response.data?.Data);
        } else {
          toast.error(response.data.Message);
        }
        setIsLoading(false);
        setSessionsByDate((prev) => ({ ...prev, list: [], change: false, Is_Past: null }));
        setCalendarDate(null);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }


  function LoadCalendar() {
    setIsLoading(true);
    axios
      .get(
        `${API}appointment/getsubscriberappointmentbyappointmentid?AppointmentId=${appointment?.AppointmentId}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setAppointment(response.data.Data?.AppointmentResponseModel);
          setClassesByDates(response.data.Data?.SlotsByDates);
          setMonth(response.data.Data?.AppointmentResponseModel?.Appointment_Month);
          setYear(response.data.Data?.AppointmentResponseModel?.Appointment_Year);
          if (response.data.Data?.AppointmentResponseModel?.Date_SPSIIds != null) {
            setSelectedClasstimings(JSON.parse(response.data.Data?.AppointmentResponseModel?.Date_SPSIIds));
            setSelectedClassDatetimings(response.data.Data?.SelectedSlotsByDates);
          }
          setSessionsByDate((prev) => ({ ...prev, list: [], change: true, Is_Past: null }));
          setCalendarDate(null);
        } else {
          toast.error(response.data.Message);
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }


  const updateappointment = () => {
    const subscriberId = JSON.parse(localStorage.getItem("user"))?.SubscriberId;
    setIsLoading(true);
    const subData = {
      SubscriberId: subscriberId,
      SelectedAppointmentSessions: JSON.stringify(selectedClasstimings),
      AppointmentId: appointment?.AppointmentId,
    };
    axios
      .post(
        `${API}appointment/updateappointmentslots`,
        subData,
        config
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.isSuccess) {
          handleClose();
          toast.success(response.data.Message);
          navigate("/clinic");
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (appointment) {
      setIsLoading(true);
      LoadCalendar();
    }
  }, []);

  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <BootstrapDialog className="modelheight"
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        style={{
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
      >
        {isLoading && (
          <div className="loader-popup">
            <div className="circle-loader" style={{ top: "50%" }}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}

        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerclassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
              padding: "25px",
            },

            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "white",
              },
            },
            error: {
              duration: 5000,
              theme: {
                primary: "red",
                secondary: "black",
              },
            },
          }}
        />
        <BootstrapDialogTitle
          style={{ borderBottom: "1px solid #ccc" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <div>
            {service}
          </div>
        </BootstrapDialogTitle>

        <DialogContent>
          <Helmet>
            <script
              async="true"
              src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"
            ></script>
            <script async="true" src="/script/popupFormJQuery.js"></script>
          </Helmet>
          {state.step === 0 && (
            <section
              className={i18n.language === "en" ? "ltr" : "rtl"}
              style={{ maxWidth: "100%" }}
            >
              <div style={{ paddingTop: "10px" }}>
                <p>Please Select  {sessions} Session(s) <span style={{ color: "red" }}>*</span></p>
                {Object.keys(selectedClasstimings).length > 0 && Object.keys(selectedClasstimings).length == sessions && (
                  <p><span style={{ color: "green" }}>You have selected {sessions} session(s)</span></p>
                )}
              </div>
              <div className="calendar-container">
                <div className="calendar-header" style={{ display: "flex", width: "100%", justifyContent: "space-between", paddingRight: "10px", paddingLeft: "10px", paddingBottom: "10px" }}>

                  {i18n.language === "en" ? (
                    <i onClick={() => { decreaseMonths(month, year) }}
                      className="mdi mdi mdi-chevron-left"
                      style={{ float: "end", fontSize: "25px" }}
                    ></i>
                  ) : (
                    <i onClick={() => { increaseMonths(month, year) }}
                      className="mdi mdi mdi-chevron-right"
                      style={{ float: "end", fontSize: "25px" }}
                    ></i>
                  )}
                  <h1>
                    {months[month - 1]}, {year}
                  </h1>
                  {i18n.language === "en" ? (
                    <i onClick={() => { increaseMonths(month, year) }}
                      className="mdi mdi mdi-chevron-right"
                      style={{ float: "end", fontSize: "25px" }}
                    ></i>
                  ) : (
                    <i onClick={() => { decreaseMonths(month, year) }}
                      className="mdi mdi mdi-chevron-left"
                      style={{ float: "end", fontSize: "25px" }}
                    ></i>
                  )}

                </div>
                <div className="calendar">
                  <span className="day-name">{t("Mon")}</span><span className="day-name">{t("Tue")}</span><span className="day-name">{t("Wed")}</span><span className="day-name">{t("Thu")}</span><span className="day-name">{t("Fri")}</span><span className="day-name">{t("Sat")}</span><span className="day-name">{t("Sun")}</span>
                  {ClassesByDates.map((obj, index) => {
                    return (
                      <div className={JSON.stringify(selectedClasstimings)?.includes(obj?.Date) ? 'selected-date' : 'decoration'} key={index}>
                        {/* {obj?.Is_Past && (
                          <div className="day--disabled">
                            {obj?.Date_Number > 0 && <div className="" style={{ textAlign: "center" }}>
                              {obj?.Date_Number}
                            </div>}
                          </div>
                        )}

                        {!obj?.Is_Past && (
                          <>
                            {(obj?.Date_Number > 0 && obj?.Slot_Days?.length == 0) && <div className="" style={{ textAlign: "center", paddingTop: "5px" }}>
                              {obj?.Date_Number}
                            </div>}
                            {(obj?.Date_Number > 0 && obj?.Slot_Days?.length > 0) &&
                              <div className={calendarDate?.Date_Id == obj?.Date_Id ? 'active-session' : ''}
                                style={{ textAlign: "center", cursor: "pointer", paddingTop: "5px" }}>
                                <div
                                  className={"radio-btn"}
                                  onClick={() => {
                                    setCalendarDate(obj);
                                    getSessionsForDates(obj);
                                  }}
                                >
                                  {obj?.Date_Number}
                                  <p><span className="availabel_Seesions"></span></p>
                                </div>
                              </div>
                            }
                          </>
                        )} */}

                        <>
                          {(obj?.Date_Number > 0 && obj?.Slot_Days?.length == 0) && <div className="" style={{ textAlign: "center", paddingTop: "5px" }}>
                            {obj?.Date_Number}
                          </div>}
                          {(obj?.Date_Number > 0 && obj?.Slot_Days?.length > 0) &&
                            <div className={calendarDate?.Date_Id == obj?.Date_Id ? 'active-session' : ''}
                              style={{ textAlign: "center", cursor: "pointer", paddingTop: "5px" }}>
                              <div
                                className={"radio-btn"}
                                onClick={() => {
                                  setCalendarDate(obj);
                                  getSessionsForDates(obj);
                                }}
                              >
                                {obj?.Date_Number}
                                <p><span className="availabel_Seesions"></span></p>
                              </div>
                            </div>
                          }
                        </>

                      </div>
                    );
                  })}

                </div>
                <div className="" style={{ width: "100%", maxHeight: "100%", overflowY: "visible" }}>
                  {SessionsByDate?.list?.map((x, _i) => {
                    return (
                      <div className={SessionsByDate?.Is_Past ? 'day--disabled' : ''}>
                        <div className={!x.IsAvailable && !x.IsSelected ? 'notavailable1' : ''} key={x.Date_SPSIId}>
                          <div onClick={selectClassTimings(x, SessionsByDate?.Is_Past)} className={JSON.stringify(selectedClasstimings)?.includes(x.Date_SPSIId) ? 'active-session' : ''}>
                            <div className="col-lg-12 chckboxstyle" >
                              <div className="row rowCustomepaddingMargin" >
                                <div className="col-lg-12 borderRightCheckbox" style={{ paddingRight: "0px" }}>
                                  <p style={{ marginBottom: "2px" }}><b style={{ fontWight: "600" }}>
                                    <i className="mdi mdi-timer"></i> &nbsp;
                                    {x.SlotFrom} - {x.SlotTo}
                                  </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
          )}


          {state.step === 1 && (
            <section className={i18n.language === "en" ? "ltr" : "rtl"}>
              <div className="receipt">
                <header className="receipt__header">
                  <p
                    className="receipt__title"
                    style={{ paddingTop: "10px", fontSize: "1.2rem" }}
                  >
                    {t("Summary")}
                  </p>
                </header>
                <dl className="receipt__list">
                  <div className="receipt__list-row">
                    <dt className="receipt__item">{t("Therapist")} </dt>
                    <dd className="receipt__cost">{therapistName}</dd>
                  </div>
                  <div className="receipt__list-row">
                    <dt className="receipt__item">{t("Sessions")}</dt>
                    <dd className="receipt__cost">{sessions}</dd>
                  </div>
                  <div className="receipt__list-row">
                    <dt className="receipt__item">{t("AppointmentDate")}</dt>
                    <dd className="receipt__cost">
                      <div style={{ display: "inline-grid" }}>
                        {selectedClassDatetimings?.map((s, _i) => {
                          return (
                            <span>{s.Date} ({s.SlotFrom} - {s.SlotTo}) </span>
                          )
                        })}
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
            </section>
          )}


        </DialogContent>
        <DialogActions style={{ overflowX: "hidden" }}>
          <div style={{ display: "contents" }}>
            {state.step === 1 && (
              <button
                className="buttonPre"
                onClick={() => dispatch({ type: ACTIONS.previous })}
              >
                {i18n.language === "en" ? (
                  <i
                    className="mdi mdi mdi-chevron-left"
                    style={{ float: "end" }}
                  ></i>
                ) : (
                  <i
                    className="mdi mdi mdi-chevron-right"
                    style={{ float: "end" }}
                  ></i>
                )}
                {t("Previous")}
              </button>
            )}

            {state.step === 0 && (
              <button
                disabled={(selectedClasstimings.length == 0)}
                className="button"
                onClick={() => {
                  dispatch({ type: ACTIONS.next });
                }}
              >
                {t("Next")}
                {i18n.language === "en" ? (
                  <i
                    className="mdi mdi mdi-chevron-right"
                    style={{ float: "end" }}
                  ></i>
                ) : (
                  <i
                    className="mdi mdi mdi-chevron-left"
                    style={{ float: "end" }}
                  ></i>
                )}
              </button>
            )}


            {state.step === 1 && (
              <button className="button" onClick={updateappointment}>
                {t("UpdateAppointment")}
              </button>
            )}


          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default CalendarPopup;
