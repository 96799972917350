import React, { useEffect, useState, useReducer, useContext } from "react";
import authContext from "../../auth-context";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { API } from "../../Constants/Global";

const Login = () => {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const { token, isLoggedIn } = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({ mode: "all" });

  const mobNum = watch("mobile", "");
  localStorage.setItem("mobNum", mobNum);

  const onSubmit = (data) => {
    setIsLoading(true);
    const mobile = data.mobile;
    let config = {
      headers: {
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}public/getloginotp?Mobile=${mobile}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          const loginId = response.data.Data;
          localStorage.setItem("loginId", loginId);
          setIsLoading(false);
          navigate("/verify");
        } else {
          toast.error(response.data.Message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      });
  };


  
  return (
    <div>
      {!token ? (
        isLoading ? (
          <div className="loader-body">
            <div className="circle-loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
        <AuthLayout>
       
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
                padding: "25px",
              },
              // Default options for specific types
              success: {
                duration: 5000,
                theme: {
                  primary: "green",
                  secondary: "white",
                },
              },
              error: {
                duration: 5000,
                theme: {
                  primary: "red",
                  secondary: "black",
                },
              },
            }}
          />
          <div className="limiter">
            <div className="container-login100">
              <div className="wrap-login100 p-l-30 p-r-30 p-t-30 p-b-30">
                <form
                  className="login100-form validate-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <span
                    className="text-center p-b-20"
                    style={{ width: "100%" }}
                  >
                    <img
                      src="http://kssc.org.sa/assets/images/LogoEn.png"
                      alt=""
                      style={{ width: "70%" }}
                    />
                  </span>
                  <span className="login100-form-title p-b-55">
                    {t("login")}
                  </span>

                  <div
                    className="wrap-input100 validate-input m-b-16"
                    data-validate="Valid mobile number is required: 9665********"
                  >
                    <input
                      className="input100"
                      type="text"
                      placeholder={t("Mobile")}
                      {...register("mobile", {
                        required: {
                          value: true,
                          message: t("MobileRequired"),
                        },
                        pattern: {
                          value: /^(\+9665|9665|05|5)[0-9]{8}$/,
                          message: t("MobileInvalid"),
                        },
                      })}
                    />
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                      <span className="lnr lnr-smartphone"></span>
                    </span>
                  </div>
                  {errors.mobile && errors.mobile.type == "required" && (
                    <p style={{ color: "red" }}>{t("MobileRequired")}</p>
                  )}
                  {errors.mobile && errors.mobile.type == "pattern" && (
                    <p style={{ color: "red" }}>{t("MobileInvalid")}</p>
                  )}
                  <div className="container-login100-form-btn p-t-25">
                    <input
                      type="submit"
                      value={t("login")}
                      className="login100-form-btn"
                    />
                  </div>
                  <div className="text-center w-full p-t-40">
                    <span className="txt1">{t("new")}</span>
                    <Link
                      className="txt1 bo1 hov1"
                      to="/register"
                      style={{ textDecoration: "none", marginLeft: "1%" }}
                    >
                      {t("create")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </AuthLayout>
        )
      ) : (
        navigate("/main")
      )}
    </div>
  );
};

export default Login;
