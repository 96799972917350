import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import authContext from "../../auth-context";
import AppLayout from "./AppLayout";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import clinicIcon from "../../images/clinicIcon.png";
import noDataImg from "../../images/no-data.png";
import { API } from "../../Constants/Global";
import CalendarPopup from "./CalendarPopup";

const Clinic = () => {
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const { t, i18n } = useTranslation();
  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  const lng = localStorage.getItem("i18nextLng");
  const tkn = localStorage.getItem("token");
  let config = {
    headers: {
      APICODE: tkn,
      "Accept-Language": lng === "ar" ? "Ar" : "En",
    },
  };
  const subscriberId = JSON.parse(localStorage.getItem("user"))?.SubscriberId;

  function handleCloseModal(event, data) {
    setOpenPopup(false);
    PageLoad();
  }

  const GetAppointmentDetails = (obj) => {
    setAppointment(obj);
    setOpenPopup(true);
  }

  function PageLoad() {
    setIsLoading(true);
    axios
      .get(
        `${API}appointment/getsubscriberappointments?SubscriberId=${subscriberId}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setAppointments(response.data.Data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    PageLoad();
  }, []);

  return isLoading ? (
    // <div className="loader-body ng-hide" data-loading="">
    //   <div className="loader"></div>
    // </div>
    <div className="loader-body">
      <div className="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div>
      {token ? (
        <AppLayout>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
                padding: "25px",
              },
              // Default options for specific types
              success: {
                duration: 5000,
                theme: {
                  primary: "green",
                  secondary: "white",
                },
              },
              error: {
                duration: 5000,
                theme: {
                  primary: "red",
                  secondary: "black",
                },
              },
            }}
          />
          {/* <div className="page-header">
              <h3 className="page-title"><span className="page-title-icon bg-gradient-primary text-white mr-2">
                  <i className="mdi mdi-home"></i>
                </span> {t("Appointments")} </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-icon-text"
                   
                    onClick={() => navigate("/appointmentClinic")}
                  >
                    {t("BookAppointment")}
                  </button>
                </ol>
              </nav>
            </div> */}
          <div className={i18n.language === "en" ? "ltr" : "rtl"}>
            <div className="row">

              <div className="col-md-8">
                <div className={`${lngClass} page-header`}>
                  <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white mr-2">
                      <i className="mdi mdi-heart-pulse"></i>
                    </span>{" "}
                    {t("clinicalBookings")}
                  </h3>

                </div>
              </div>
              <div className="col-md-4">
                <nav aria-label="breadcrumb" className="alignment">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-icon-text"
                    onClick={() => navigate("/clinic/booking")}
                  >
                    {t("BookAppointment")}
                  </button>
                </nav>
              </div>
            </div>
          </div>

          {appointments && appointments.length != 0 ? (
            appointments?.map((obj, index) => {
              return (
                <div className="row" key={obj.AppointmentId} style={{}}>

                  <div className="col-lg-12">
                    <div className="job-card">
                      <div className={`${lngClass} job-card__content`}>
                        <div className="job-card_img">
                          <img src={clinicIcon} alt="Company Logo" />
                        </div>
                        <div className="job-card_info  width25persent">
                          <p className="labelTitle"> {t("ServiceName")} </p>
                          <p className="font-weight-bold">
                            {obj.ServiceNameEng}
                            {obj.SubServiceNameEng != null &&
                              obj.SubServiceNameEng != "" ? (
                              <>| {obj.SubServiceNameEng} </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </div>
                        <div className="job-card_info">
                          <p className="labelTitle" style={{ textAlign: "center" }}> {t("AppointmentNo")} </p>
                          <p className="font-weight-bold" style={{ textAlign: "center" }}>{obj.AppointmentNo}</p>
                        </div>

                        <div className="job-card_info">
                          <p className="labelTitle" style={{ textAlign: "center" }}> {t("Sessions")} </p>
                          <p className="font-weight-bold" style={{ textAlign: "center" }}>
                            {obj.ServiceSessions}
                          </p>
                        </div>
                        <div className="job-card_info">
                          <p className="labelTitle" style={{ textAlign: "center" }}> {t("BookedSessions")} </p>
                          <p className="font-weight-bold" style={{ textAlign: "center" }}>
                            {obj?.Date_SPSIIds != null ? JSON.parse(obj?.Date_SPSIIds)?.length : 0}
                          </p>
                        </div>
                        <div className="job-card_info">
                          <p className="labelTitle" style={{ textAlign: "center" }}> {t("Therapist")} </p>
                          <p className="font-weight-bold" style={{ textAlign: "center" }}>{obj.TherapistName}</p>
                        </div>
                        <div className="job-card_info width0">
                          {/* <p className="labelTitle" style={{ textAlign: "center" }}> {t("Status")} </p> */}
                          <p className="labelTitle" style={{ textAlign: "center" }}> &nbsp; </p>

                          <p className="font-weight-bold" style={{ textAlign: "center" }}>
                            {(() => {
                              switch (obj.AppointmentStatusId) {
                                case 5:
                                  return (
                                    <span className="badge badge-warning">
                                      Payment Failed
                                    </span>
                                  );
                                case 2:
                                  return (
                                    <span className="badge badge-success">
                                      Paid
                                    </span>
                                  );
                                case 3:
                                  return (
                                    <span className="badge badge-danger">
                                      Cancelled
                                    </span>
                                  );
                                default:
                                  return "---";
                              }
                            })()}
                          </p>
                        </div>
                        <div className="job-card_info" >
                          <p className="labelTitle" style={{ textAlign: "center" }}> &nbsp; </p>

                          <div
                            style={{ textAlign: "end" }}
                          >
                            <button style={{ lineHeight: "1" }}
                              type="link"
                              onClick={() => GetAppointmentDetails(obj)
                              }
                            >
                              <i className="mdi mdi-calendar-multiple" style={{ fontSize: "30px", color: "#012690" }}></i>{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="empty-state"
              style={{ backgroundColor: "transparent", paddingTop: "6%" }}
            >
              <div className="empty-state__content">
                <div className="empty-state__icon">
                  <img src={noDataImg} alt="" />
                </div>
                <div className="empty-state__message">{t("NoRecordS")}</div>
              </div>
            </div>
          )}

          {openPopup && appointment && (
            <CalendarPopup
              open={openPopup}
              onCloseModal={handleCloseModal}
              appointment={appointment}
            />
          )}
        </AppLayout>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};

export default Clinic;
