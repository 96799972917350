import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import CloseIcon from "@mui/icons-material/Close";
import "./custom.scss";
import img from "../../images/no-data.png";
import OtpInput from "react-otp-input";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "25px",
  },
  "& .MuiDialogActions-root": {
    padding: "10px",
  },
  "& .MuiInputLabel-root": {
    color: "#43425d",
    fontWeight: "bold",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    width: "360px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const { t, i18n } = useTranslation();
  return (
    <DialogTitle
      className={i18n.language === "en" ? "ltr" : "rtl"}
      sx={{ m: 0, p: 2 }}
      {...other}
      // style={{
      //   direction: i18n.language === "en" ? "ltr" : "rtl",
      // }}
    >
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: i18n.language === "en" ? 8 : "auto",
            left: i18n.language === "en" ? "auto" : 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
          }}
        >
          {/* <Icon>
            <CloseIcon />
          </Icon> */}
        </CloseIcon>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const defaultValues = {
  account_name: "",
};

const MobilePopup = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [OTP, setOTP] = useState("");
  const [error, setError] = useState(false);

  function handleChange(event, data) {
    setOTP(event);
    setError(false);
  }

  // const resend = () => {
  //   const loginId = localStorage.getItem("loginId");
  //   let config = {
  //     headers: {
  //       "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
  //     },
  //   };
  //   axios
  //     .get(
  //       `https://test-api.kssc.org.sa/api/public/resendloginotp?Id=${loginId}`,
  //       config
  //     )
  //     .then((response) => {
  //       if (response.data.isSuccess) {
  //         toast.success(response.data.Message);
  //       } else {
  //         toast.error(response.data.Message);
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // };

  const ACTIONS = {
    next: "next",
    previous: "previous",
  };

  const lng = localStorage.getItem("i18nextLng");

  const formReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.next:
        return { step: state.step + 1 };
      case ACTIONS.previous:
        return { step: state.step - 1 };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formReducer, { step: 0 });

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  const handleClose = () => {
    props.onCloseModal(false);
  };

  const { t, i18n } = useTranslation();

  const [openAlert, setOpenAlert] = useState(false);
  const [icon, setIcon] = useState("mail_outline");

  function handleCloseModal(event, data) {
    setOpenAlert(false);
  }

  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  const mobNum = localStorage.getItem("mobNum");

  const newMob = watch("newMobile");

  return (
    <div
   
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <BootstrapDialog  className="ChangeMobNum"
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        style={{
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
      >
        {/* {isLoading && (
          <div className="loader-popup">
            <div className="circle-loader" style={{ top: "50%" }}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )} */}
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerclassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
              padding: "25px",
            },
            // Default options for specific types
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "white",
              },
            },
            error: {
              duration: 5000,
              theme: {
                primary: "red",
                secondary: "black",
              },
            },
          }}
        />
        <BootstrapDialogTitle
          style={{ borderBottom: "1px solid #ccc" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <div>
            {state.step === 1 && (
              <span>
                {" "}
                <i
                  className="mdi mdi mdi-chevron-left"
                  style={{ fontSize: "25px", color: "#012690" }}
                  onClick={() => dispatch({ type: ACTIONS.previous })}
                ></i>
              </span>
            )}
            <span>  {t("ChangeMobNum")}</span>
          </div>
        </BootstrapDialogTitle>
        <DialogContent >
          <Helmet>
            <script
              async="true"
              src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"
            ></script>
            <script async="true" src="/script/popupFormJQuery.js"></script>
          </Helmet>

          {state.step === 0 && (
            <div className="row mt-5">
              <div className="col-md-12">
                <div className={`${lngClass} wrap-input100 validate-input m-b-16`}
                  data-validate="Valid mobile number is required: 9665********"
                >
                  <label className="form-control-label" htmlFor="input-email">
                    {t("EnternewNo")}
                  </label>

                  <input
                    className="input1001"
                    type="text"
                    style={{ padding: "padding: 0 16px 0 16px" }}
                    {...register("newMobile", {
                      required: {
                        value: true,
                        message: t("MobileRequired"),
                      },
                      pattern: {
                        value: /^(\+9665|9665|05|5)[0-9]{8}$/,
                        message: t("MobileInvalid"),
                      },
                    })}
                  />
                </div>

                {errors.newMobile && errors.newMobile.type == "required" && (
                  <p style={{ color: "red" }}>{t("MobileRequired")}</p>
                )}
                {errors.newMobile && errors.newMobile.type == "pattern" && (
                  <p style={{ color: "red" }}>{t("MobileInvalid")}</p>
                )}
              </div>
            </div>
          )}
          {state.step === 1 && (
            <div className="limiter">
              <div className="">
                <div className="wrap-login100 p-l-30 p-r-30 p-t-30 p-b-30">
                  <form
                    className="login100-form validate-form"
                    // onSubmit={handleSubmit(onSubmit)}
                  >
                    <span
                      className="text-center p-b-20"
                      style={{ width: "100%" }}
                    >
                      {/* <img
                        src="http://kssc.org.sa/assets/images/LogoEn.png"
                        alt=""
                        style={{ width: "70%" }}
                      /> */}
                    </span>

                    <span className="login100-form-title p-b-20">
                      {t("verification ")}
                    </span>
                    <span
                      className="p-b-42"
                      style={{
                        color: "#ccc",
                        lineHeight: "1.2",
                        textTransform: "none",
                        width: "100%",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      {t("code")} <span>+966{newMob}</span>
                    </span>

                    <div className="mb-6 text-center">
                      <div
                        id="otp"
                        className="flex justify-center wrap- validate-input"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <OtpInput
                          value={OTP}
                          onChange={handleChange}
                          numInputs={4}
                          shouldAutoFocus="false"
                          isInputSecure="false"
                          containerStyle={"otp-div"}
                          inputStyle={"otp-input"}
                        />
                        {error && (
                          <p style={{ color: "red" }}>{t("OTPRequired")}</p>
                        )}
                      </div>
                    </div>

                    <div className="text-center w-full p-t-40">
                      <span className="txt1">{t("Receive")}</span>
                      <Link
                        className="txt1 bo1 hov1"
                        to="#"
                        style={{ textDecoration: "none", margin: "0 2% 0 1%" }}
                      >
                        {t("Resend")}
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {state.step !== 1 && (
            <button
              className="button"
              onClick={() => dispatch({ type: ACTIONS.next })}
              disabled={newMob && !errors.newMobile ? false : true}
            >
             

              {t("Next")}
                {i18n.language === "en" ? (
                  <i
                    className="mdi mdi mdi-chevron-right"
                    style={{ float: "end" }}
                  ></i>
                ) : (
                  <i
                    className="mdi mdi mdi-chevron-left"
                    style={{ float: "end" }}
                  ></i>
                )}


            </button>
          )}
          {state.step === 1 && (
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-6">
                  {/* <button
                className="button"
                onClick={() => dispatch({ type: ACTIONS.previous })}
                
              >
              <i className="mdi mdi mdi-chevron-left" onClick={() => dispatch({ type: ACTIONS.previous })}></i>
                Previous
              </button> */}
                </div>
                <div className="col-md-12">
                  <div
                    className="form-btn p-t-0"
                    style={{ textAlign: "center" }}
                  >
                    <button
                      // type="submit"
                      disabled={OTP.length !== 4}
                      className="button"
                    >
                      {t("verify")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default MobilePopup;
