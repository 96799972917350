import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import CloseIcon from "@mui/icons-material/Close";
import { addDays } from "date-fns";
import img from "../../images/pass-icon.png";
import noDataImg from "../../images/no-data.png";
import "../../CustomCSS/custom.css";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { API } from "../../Constants/Global";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "25px",
  },
  "& .MuiDialogActions-root": {
    padding: "10px",
  },
  "& .MuiInputLabel-root": {
    color: "#43425d",
    fontWeight: "bold",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    width: "360px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const { t, i18n } = useTranslation();

  return (
    <DialogTitle
      className={i18n.language === "en" ? "ltr" : "rtl"}
      sx={{ m: 0, p: 2 }}
      {...other}
      // style={{
      //   direction: i18n.language === "en" ? "ltr" : "rtl",
      // }}
    >
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: i18n.language === "en" ? 8 : "auto",
            left: i18n.language === "en" ? "auto" : 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
          }}
        >
          {/* <Icon>
              <CloseIcon />
            </Icon> */}
        </CloseIcon>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const defaultValues = {
  account_name: "",
};

const GatePassPopup = (props) => {
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [isLoading, setIsLoading] = useState(true);

  let date = watch("Date");
  const sessionId = watch("SessionId");

  const [sessions, setSessions] = useState(null);
  const [activePasses, setActivePasses] = useState(null);
  const navigate = useNavigate();

  function convert(str) {
    const date = new Date(str);
    const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  useEffect(() => {
    const lng = localStorage.getItem("i18nextLng");
    const tkn = localStorage.getItem("token");
    const subscriberId = JSON.parse(
      localStorage.getItem("user") != "undefined" &&
        localStorage.getItem("user")
    )?.SubscriberId;

    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": lng === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}subscriber/getactivegatepasses?SubscriberId=${subscriberId}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setActivePasses(response.data.Data);
          setIsLoading(false);
        } else {
          setActivePasses(response.data.Data);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!date) {
      date = new Date();
    }

    if (date) {
      const Date = convert(date);
      const lng = localStorage.getItem("i18nextLng");

      let config = {
        headers: {
          "Accept-Language": lng === "ar" ? "Ar" : "En",
        },
      };
      axios
        .get(
          `${API}public/getsession?Date=${Date}`,
          config
        )
        .then((response) => {
          if (response.data.isSuccess) {
            setSessions(response.data.Data);
          } else {
            setSessions(response.data.Data);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [date]);

  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") return;
    props.onCloseModal(false);
  };

  const Submit = () => {
    const selectedDate = convert(date);
    const lng = localStorage.getItem("i18nextLng");
    const subscriberId = JSON.parse(
      localStorage.getItem("user") != "undefined" &&
        localStorage.getItem("user")
    )?.SubscriberId;
    const sessionID = sessionId;
    const tkn = localStorage.getItem("token");

    const subData = {
      SubscriberId: subscriberId,
      Date: selectedDate,
      SessionID: sessionID,
    };

    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": lng === "ar" ? "Ar" : "En",
      },
    };
    axios
      .post(
        `${API}subscriber/gatepass`,
        subData,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          toast.success(response.data.Message);
          handleClose();
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) => console.log(error));
  };
  const { t, i18n } = useTranslation();

  const [openAlert, setOpenAlert] = useState(false);
  const [icon, setIcon] = useState("mail_outline");
  const maxDate = new Date() + 3;

  const ACTIONS = {
    next: "next",
    previous: "previous",
  };

  const formReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.next:
        return { step: state.step + 1 };
      case ACTIONS.previous:
        return { step: state.step - 1 };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formReducer, { step: 0 });

  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <BootstrapDialog className="modelWith "
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        style={{
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
      >
        {isLoading && (
          <div className="loader-popup">
            <div className="circle-loader" style={{ top: "50%" }}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
              padding: "25px",
            },
            // Default options for specific types
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "white",
              },
            },
            error: {
              duration: 5000,
              theme: {
                primary: "red",
                secondary: "black",
              },
            },
          }}
        />
        <BootstrapDialogTitle
          style={{ borderBottom: "1px solid #ccc" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <div>
            <h3>{t("GatePass")}</h3>
          </div>
        </BootstrapDialogTitle>
        <DialogContent>
          {state.step === 0 && (
            <section
              className={i18n.language === "en" ? "ltr" : "rtl"}
            >
              <div>
                <div className="">
                  <div>
                    <div className="three">
                      <h1>{t("YourGatePasses")}</h1>
                    </div>
                  </div>
                  <div
                    className="plans"
                    style={{
                      overflowY: "auto",
                      maxHeight: "300px",
                      justifyContent: "center",
                    }}
                  >
                    {activePasses && activePasses.length != 0 ? (
                      activePasses?.map((obj, index) => {
                        return (
                          <label className="plan basic-plan">
                            <div className="plan-content">
                              <img loading="lazy" src={img} alt="" />
                              <div
                                className="plan-details"
                                style={{ width: "100%" }}
                              >
                                <div>
                                  <div className="sportsValue">
                                    <span>
                                      {i18n.language === "en"
                                        ? obj.SessionNameEng
                                        : obj.SessionNameAra}
                                    </span>
                                    <span>{obj.Date}</span>
                                  </div>
                                  <span>
                                    {obj.StartTime} - {obj.EndTime}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </label>
                        );
                      })
                    ) : (
                      <div className="empty-state">
                        <div className="empty-state__content">
                          <div className="empty-state__icon">
                            <img src={img} alt="" />
                          </div>
                          <div className="empty-state__message">
                            {t("NoGatePasses")}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <label className="plan basic-plan">
                      <div className="plan-content">
                        <img loading="lazy" src={img} alt="" />
                        <div className="plan-details" style={{ width: "100%" }}>
                          <div>
                            <div className="sportsValue">
                              <span>First</span>
                              <span>26-02-2022</span>
                            </div>
                            <span>10:00 AM - 11:00 AM</span>
                          </div>
                        </div>
                      </div>
                    </label>
                    <label className="plan basic-plan">
                      <div className="plan-content">
                        <img loading="lazy" src={img} alt="" />
                        <div className="plan-details" style={{ width: "100%" }}>
                          <div>
                            <div className="sportsValue">
                              <span>First</span>
                              <span>26-02-2022</span>
                            </div>
                            <span>10:00 AM - 11:00 AM</span>
                          </div>
                        </div>
                      </div>
                    </label> */}
                  </div>
                </div>
              </div>
            </section>
          )}
          {state.step === 1 && (
            <section className={i18n.language === "en" ? "ltr" : "rtl"}>
              <div>
                <div className="three">
                  <h1>{t("selectDate")}</h1>
                </div>
              </div>
              <div className="wrap-input100 validate-input m-t-10">
                <Controller
                  name="Date"
                  control={control}
                  defaultValue={new Date()}
                  rules={{
                    required: {
                      value: true,
                      message: "Date is required",
                    },
                  }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Date"
                        views={["year", "month", "day"]}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        className="form-control"
                        minDate={new Date()}
                        maxDate={addDays(new Date(), 2)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
                
              </div>

              <div>
                <div
                  className="plans"
                  style={{ overflowY: "auto", maxHeight: "300px" }}
                >
                  {sessions && sessions.length != 0 ? (
                    sessions?.map((obj, index) => {
                      return (
                        <div>
                          <div
                            className="choice-title"
                            style={{ marginBottom: "0px" }}
                          >
                            <div>
                              <h4>{t("passSlot")}</h4>
                            </div>
                          </div>
                          <label
                            className="plan basic-plan "
                            htmlFor={`title-${index}`}
                            style={{ width: "100%" }}
                          >
                            <input
                              type="radio"
                              value={obj.SessionId}
                              {...register("SessionId")}
                              id={`title-${index}`}
                              disabled={obj.isAvailable ? false : true}
                            />
                            <div className="plan-content">
                              <div className="plan-details">
                                <span>
                                  {i18n.language === "en"
                                    ? obj.NameEng
                                    : obj.NameAra}
                                </span>
                                <p>
                                  {obj.StartTime}-{obj.EndTime}
                                </p>
                              </div>
                              {/* <div style={{ margin: "auto" }}>
                                    <i
                                      className="mdi mdi mdi-chevron-right"
                                      style={{ fontSize: "22px" }}
                                    ></i>
                                  </div> */}
                            </div>
                          </label>
                        </div>
                      );
                    })
                  ) : (
                    <div className="empty-state" style={{ margin: "0 auto" }}>
                      <div className="empty-state__content">
                        <div className="empty-state__icon">
                          <img src={noDataImg} alt="" />
                        </div>
                        <div className="empty-state__message">
                          {t("sessionsNotAvailable")}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}
        </DialogContent>
        <DialogActions>
          {state.step === 0 && (
            <div className="col-md-12">
              <center>
                <button
                  className="button"
                  onClick={() => dispatch({ type: ACTIONS.next })}
                >
                  <i className="mdi mdi-account-card-details"></i>{" "}
                  {t("ReqGatepass")}
                </button>
              </center>
            </div>
          )}
          {state.step === 1 && (
            <div style={{ width: "100%" }}>
              <button
                className="buttonPre"
                onClick={() => dispatch({ type: ACTIONS.previous })}
              >
                {i18n.language === "en" ? (
                  <i
                    className="mdi mdi mdi-chevron-left"
                    style={{ float: "end" }}
                  ></i>
                ) : (
                  <i
                    className="mdi mdi mdi-chevron-right"
                    style={{ float: "end" }}
                  ></i>
                )}
                {t("Previous")}
              </button>
              <button
                className="button"
                disabled={!sessionId && true}
                onClick={Submit}
                style={{ float: "right" }}
              >
                {t("submit")}
              </button>
            </div>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default GatePassPopup;
