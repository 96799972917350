import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authContext from "../../auth-context";
import AppLayout from "./AppLayout";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import Dashboardlogo from "../../images/Logo1.png";
import maleIcon from "../../images/male.jpeg";
import femaleIcon from "../../images/female.jpeg";
import RenewalPopup from "./RenewalPopup";
import HistoryPopup from "./HistoryPopup";
import SubscribePopup from "./SubscribePopup";
import MobilePopup from "./MobilePopup";
import FreezeRequestPopup from "./FreezeRequestPopup";

import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
const Main = () => {
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);

  const [user, setUser] = useState(null);
  const [openRenewalPopup, setOpenRenewalPopup] = useState(false);
  const [openHistoryPopup, setOpenHistoryPopup] = useState(false);
  const [openSubscribePopup, setOpenSubscribePopup] = useState(false);
  const [openFreezePopup, setOpenFreezePopup] = useState(false);
  const navigate = useNavigate();
  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  function handleCloseModal(event, data) {
    RenewalPopup(false);
    HistoryPopup(false);
  }
  let fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 5000));
  };

  function handleCloseModal(event, data) {
    setOpenPopup(false);
    setOpenRenewalPopup(false);
    setOpenHistoryPopup(false);
    setOpenSubscribePopup(false);
    setOpenFreezePopup(false);
  }

  useEffect(() => {
    fakeRequest().then(() => {
      debugger;
      setUser(JSON.parse(localStorage.getItem("user")));
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    // <div className="loader-body ng-hide" data-loading="">
    //   <div className="loader"></div>
    // </div>
    <div className="loader-body">
      <div className="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div>
      {token ? (
        <AppLayout>
          <div className="page-header">
            {/* <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-home"></i>
              </span>{" "}
              {t("Dashboard")}
            </h3> */}
            <nav aria-label="breadcrumb"></nav>
          </div>
          {/* <div className="row">
            <div className="col-md-12" style={{ textAlign: "center", paddingTop: "20px" }}>
              <img src={Dashboardlogo} style={{ width: "25%" }} />
              <h2 style={{ color: "#253c78" }}>Subscriber Dashboard</h2>
            </div>
          </div> */}

          { user &&
            <div className="row d-flex flex-column flex-md-row">
            <div className="col-12 mb-5">
              <div className="card card-profile shadow-lg p-1">
                <div className="row no-gutters d-flex align-items-center flex-md-row flex-column">
                  <div className="col-lg-4 col-md-4 col-sm-12 text-center p-1">
                    <div className="card-profile-image mb-3">
                      <input type="file" className="custom-file-input fileCustome" />
                      <img
                        src={user?.GenderId == 1 ? maleIcon : femaleIcon}
                        className="rounded-circle img-fluid"
                        alt="Profile"
                        style={{ maxWidth: "150px", border: "4px solid #fff", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}
                      />
                    </div>
                    <div className="profile-details">
                      <h3 className="user-name">{user?.Name}</h3>
                      <div className="user-info">
                        <i className="ni ni-badge mr-2"></i>{user?.SubscriberNo}
                      </div>
                      <div className="user-info">
                        <i className="ni ni-mobile mr-2"></i>{user?.Mobile}
                      </div>
                      <div className="user-info">
                        <i className="ni ni-email-83 mr-2"></i>{user?.Email}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-12 p-0">
                    <div className="card-body">
                      <div className="row mt-1">
                        <div className="col-md-12">
                          {user?.SubscriberTypeId > 0 ? (
                            <div className="pricing-table subscription-card">
                              <h3 className="subscription-title">{t("Subscription")}</h3>
                              {user?.NoOfRemainingDays <= 0 ? (
                                <div className="ribbon-wrapper expired-ribbon">
                                  <div className="ribbon">{t("Expired")}</div>
                                </div>
                              ) : (
                                <div className="ribbon-wrapper days-left-ribbon">
                                  <div className="ribbon">{user?.NoOfRemainingDays} {t("Days Left")}</div>
                                </div>
                              )}
                              <div className="pricing-features mb-4">
                                <div className="feature">
                                  <strong>{t("Type")}:</strong>
                                  <span>{i18n.language === "en" ? user?.SubscriberNameEng : user?.SubscriberNameAra}</span>
                                </div>
                                <div className="feature">
                                  <strong>{t("ValidFrom")}:</strong>
                                  <span>{user?.StartDate}</span>
                                </div>
                                <div className="feature">
                                  <strong>{t("ValidTo")}:</strong>
                                  <span>{user?.EndDate}</span>
                                </div>
                                <div className="feature">
                                  <strong>{t("Fees")}:</strong>
                                  <span>SAR {user?.Fees}</span>
                                </div>
                              </div>
                              <div className="button-group">
                                {user?.NoOfRemainingDays <= 30 && (
                                  <button
                                    className="btn btn-lg btn-gradient-primary renewal-btn"
                                    onClick={() => setOpenRenewalPopup(true)}
                                  >
                                    <i className="mdi mdi-account-card-details"></i> {t("Renewal")}
                                  </button>
                                )}

                                {user?.MaxDiff > 0 && (
                                  <button
                                    className="btn btn-outline-primary freeze-btn"
                                    onClick={() => setOpenFreezePopup(true)}
                                  >
                                    <i className="mdi mdi-account-card-details"></i> {t("Freeze")}
                                  </button>
                                )}

                                <button
                                  className="btn btn-link history-btn"
                                  onClick={() => setOpenHistoryPopup(true)}
                                >
                                  <i className="mdi mdi-history"></i> {t("SubscriptionHistory")}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="pricing-table not-subscriber">
                              <h4>{t("NotSubscriber")}</h4>
                              <button
                                className="btn btn-block btn-lg btn-gradient-primary mt-0 subscribe-btn"
                                onClick={() => setOpenSubscribePopup(true)}
                              >
                                <i className="mdi mdi-account-card-details"></i> {t("Subscribe")}
                              </button>
                            </div>
                          )}
                        </div>


                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          }
          





          {openPopup && (
            <MobilePopup open={openPopup} onCloseModal={handleCloseModal} />
          )}
          {openRenewalPopup && (
            <RenewalPopup open={openRenewalPopup} onCloseModal={handleCloseModal} />
          )}
          {openHistoryPopup && (
            <HistoryPopup open={openHistoryPopup} onCloseModal={handleCloseModal} />
          )}
          {openSubscribePopup && (
            <SubscribePopup open={openSubscribePopup} onCloseModal={handleCloseModal} />
          )}
          {openFreezePopup && (
            <FreezeRequestPopup open={openFreezePopup} onCloseModal={handleCloseModal} />
          )}

        </AppLayout>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};

export default Main;
